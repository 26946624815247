const state = {
  firstTimeUserStatus: true,
  selectPaymentReference: false,
  selectPaymentModal: true,
  fixedAccountModal: true,
  userDetails: "",
  userGuides: false,
  kycDocumentState: "",
  fromFixedAccount: false,
  accounts: [
    {
      name: "Settlement Account",
      type: "Setting Up Settlement Account",
      id: "settingToggle",
      rid: "PaymentMethod",
      summary:
        "Traction allows you to receive payment from your customers with different payment methods and a settlement account is needed to receive settlements for all payment transactions.",
      texts: [
        {
          id: 1,
          name:
            "To add a settlement account start by clicking on the slide nav, on the left hand side of the page.",
        },
        {
          id: 2,
          name:
            "Then click on the settings. There you will see settlement account.",
        },
        {
          id: 3,
          name:
            "Upon entrance to the settlement account page, click on  “ Add a bank account” button.",
        },
        {
          id: 4,
          name:
            "The next step is to fill out the necessary fields of information, including your bank account number and the bank name.",
        },
        {
          id: 5,
          name:
            "The app verifies the account showing your account name, the next step is to enter your bank verification number to verify the account ownership.",
        },
      ],
    },
    { name: "Sale", type: "Making your first sale" },
  ],
  currentGuide: 0,
  currentText: "",
  currentSummary: "",
  kyc: {
    pic: "",
  },
};
const getters = {
  GET_FIRST_TIME_STATUS: (state) => {
    return state.firstTimeUserStatus;
  },
  GET_FROM_FIXED_ACCOUNT: (state) => {
    return state.fromFixedAccount
  },
  GET_USER_GUIDES_STATUS: (state) => {
    return state.userGuides;
  },
  GET_CURRENT_GUIDE: (state) => {
    return state.currentGuide;
  },
  GET_CURRENT_TEXT: (state) => {
    return state.currentText;
  },
  GET_ACCOUNTS: (state) => {
    return state.accounts;
  },
  GET_KYC_PIC: (state) => {
    return state.kyc.pic;
  },
  GET_EMPLOYEE_DATA: (state) => {
    return state.userDetails;
  },
  GET_KYC_DOCUMENT_STATE: (state) => {
    return state.kycDocumentState;
  }
};
const mutations = {
  UPDATE_KYC_DOC_STATUS: (state, payload) => {
    state.kycDocumentState = payload
  },
  UPDATE_KYC(state, payload) {
    state.kyc.pic = payload;
  },
  UPDATE_FROM_FIXED_ACCOUNT_STATUS: (state, payload) => {
    state.fromFixedAccount = payload;
  },
  FIND_CURRENT_GUIDE: (state, payload) => {
    let currentAccount = state.accounts.find(
      (account) => account.id === payload
    );

    let lastElement = currentAccount.texts[currentAccount.texts.length - 1];
    if (!lastElement) {
      state.currentText = "Thank you";
    } else {
      state.currentText = currentAccount.texts[state.currentGuide].name;
    }
  },
  INCREASE_CURRENT_GUIDE: (state) => {
    state.currentGuide = state.currentGuide += 1;
  },
  CHANGE_FIRSTTIME_STATUS: (state) => {
    state.firstTimeUserStatus = false;
  },
  CHANGE_PAYMENT_REFERENCE: (state) => {
    state.selectPaymentReference = true;
  },
  CHANGE_PAYMENT_MODAL: (state) => {
    state.selectPaymentModal = false;
  },
  CHANGE_FIXED_ACCOUNT_MODAL: (state) => {
    state.fixedAccountModal = false;
  },
  setUserDetails: (state, payload) => {
    state.userDetails = payload;
  },
  CHANGE_USER_GUIDE: (state, payload) => {
    state.userGuides = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
