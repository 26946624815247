<template>
  <div
    :class="{ goneonline: isOnline, goneoffline: !isOnline }"
    class="px-6 relative small-shadow rounded-md py-4 mb-6"
    v-if="eventuallyShow"
  >
    <div v-if="isOnline">
      <h5 class="text-sm font-semibold">You are online!</h5>
      <p class="text-xs">
        You are back online. Any offline data is being synced
      </p>
    </div>
    <div v-else>
      <h5 class="text-sm font-semibold">You are offline</h5>
      <p class="text-xs">
        You don't have a working internet connection at the moment. Traction
        Apps will save existing data until you come back online
      </p>
    </div>
  </div>
</template>

<script>
import { Network } from "@capacitor/network";
export default {
  data() {
    return {
      eventuallyShow: false
    };
  },
  watch: {
    isOnline(newValue, oldValue) {
        if (newValue === false) {
            this.eventuallyShow = true
        } else {
            this.delayHiding()
        }
    }
  },
  async created () {
    this.isOnline = await (await Network.getStatus()).connected
  },
  methods: {
    delayHiding() {
        const delayHide = setTimeout(() => {
            this.eventuallyShow = false
            clearTimeout(delayHide)
        }, 4000)
    }
  },
};
</script>

<style lang="postcss" scoped>
.goneoffline {
  @apply bg-red-200 border border-red-400;
}
.goneonline {
  @apply bg-green-200 border border-green-400;
}
</style>