<template>
  <div id="app" class="bg-backgroundArea">
    <!-- <button @click="clearStuff" class="block m-20">Clear</button> -->
    <router-view/>
    <div v-if="updateExists" class="z-50 fixed inset-0 w-full bg-black bg-opacity-50 overflow-y-auto pt-24">
      <div class="w-full mx-auto text-white btn-gradient rounded-lg relative light p-12 mt-16 max-w-xl">
          <main>
              <h3 class="mb-4 font-bold text-lg">Critical New Update </h3>
              <p class="text-sm my-3">We have a new update with a lot of fixes to serve you better</p>
              <p class="text-sm my-3">As this is a major push, we would need you to update to the latest version</p>
              <div class="flex justify-start">
                <button v-if="refreshing === false" @click="refreshing = true; doUpgrade()" class="border border-white px-3 py-2 rounded-xl mt-4">
                  <span class="text-xs text-white">Click to update</span>
                </button>
                <button class="border border-white px-3 py-2 rounded-xl mt-4" v-else>
                  <span class="text-xs text-white">Updating. This might take a while....</span>
                </button>
              </div>
          </main>
      </div>
    </div>
    <!-- <div
      v-if="updateExists"
      class="
        fixed
        bottom-0
        cursor-pointer
        max-w-sm
        w-full
        right-0
        inline-block
        m-10
        px-6
        py-4
        shadow-md
        btn-gradient
        rounded-md
        z-40
      "
    >
      <div class="flex items-center justify-start mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          class="stroke-current text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#909090"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        <p class="text-xs font-semibold text-white ml-2">
          New version available!
        </p>
      </div>
      <div class="flex justify-end">
        <button v-if="!refreshing" @click="doUpgrade()">
          <span class="text-xs text-white">Click to update</span>
        </button>
        <button v-else>
          <span class="text-xs text-white"
            >Updating. This might take a while....</span
          >
        </button>
      </div>
    </div> -->

    <!-- class="z-40" -->
    <!-- style="z-index: 99999;" -->
    <trac-confirmation
      @close="alertDetails.showNotification = $event"
      :type="true"
      :active="alertDetails.showNotification"
    >
      {{ alertDetails.alertMessage }}
    </trac-confirmation>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import Vue from 'vue'
import {
  CLEAR_KEY_IN_DB,
  CLEAR_LIST,
  SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE,
} from "./browser-db-config/indexedDB";
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
  GET_USER_DATA
} from "./browser-db-config/localStorage";
import { eventBus } from "./main";
import * as eventBusAlternate from "./eventBus";
import prepDbFirst from "./offline-module/lb-helper";
import { Scope } from "@sentry/vue";
import moment from "moment";

export default {
  data() {
    return {
      refreshing: false,
      alertDetails: {
        alertMessage: "",
        showNotification: false,
      },
      registration: null,
      updateExists: false,
      userId: null,
      name: null,
      email: null,
      loadTime: 0
    };
  },
  updated() {
    if (this.SWUpdateAvailable) {
      this.showRefreshUI();
    }
  },
  created() {
    const observer = new PerformanceObserver(this.perfObserver);
    observer.observe({ entryTypes: ["navigation"] });

    this.$SendMessageToSW({
      EVENT_PAYLOAD: {},
      EVENT_TYPE: "REQUEST_CACHE_MANIFEST",
    });
    const BID = GET_USER_BUSINESS_ID();
    const Business = GET_USER_BUSINESS_DATA();
    // debugger
    // const MixpanelPayload = JSON.parse(JSON.stringify(Business));
    // delete MixpanelPayload["kyc_status"];
    // this.checkforUpgrade();
    console.log("App.vue -> Created", BID);
    // debugger
    if (BID) {
      this.userId = BID
      this.email = Business.email || ''
      this.name = Business.name || ''
      
      this.$intercom.shutdown();
      this.$intercom.once('ready', this.bootIntercom);

      this.$InstantiateGlobalOfflineManager(BID);
      this.$requestMonitoring(); // starts the periodic monitoring of everything that happens in SW
      this.setupMerchantTrackingForSentry();
      this.setupMerchantTrackingForHotjar();

      if (Business.user_id === this.$MixpanelGetDistinctId()) {
        // console.log(`Business User ID matches Mixpanel Distinct ID ${BID} === ${this.$MixpanelGetDistinctId()}`)
        this.$SetIdentity(Business.user_id)
        this.$MixPanelRegister(Business)
        this.checkKYCstatusForMixPanel(BID) // this needs MixPanel do its stuff before firing
        // console.log('mixpanel attribute: ', attribute)
      } else {
        console.log(`Business User ID does not match Mixpanel Distinct ID ${BID} !== ${this.$MixpanelGetDistinctId()}`)
        this.$MixpanelReset()
        this.$SetIdentity(Business.user_id)
        this.$MixPanelRegister(Business)
        this.$SetPerson(Business, { set_once : true })
        this.checkKYCstatusForMixPanel(BID) // this needs MixPanel do its stuff before firing
      }

      this.$SetPersonalAttribute({
        propName : 'APP_VERSION',
        propValue: process.env.VUE_APP_VERSION
      })

      this.transactingMerchantCheck(BID)

      prepDbFirst(BID).then((allKeysTest) => {
        // debugger
        console.log('App -> prepDbFirst -> allKeysTest: ', allKeysTest)
      }).catch((err) => {
        console.error('App -> prepDbFirst -> allKeysTest error: ', err)
        Vue.sentryCaptureException(new Error(err))
      })

      // only start the periodic sync after a delay. this is to make sure
      // the syncManifest in SWController.Plugin.js is populated by the Service Worker
      // before we refer to it
      const delayBeforePeriodicSync = setTimeout(() => {
        this.$beginPeriodicSync();
        console.log("Now firing this.$beginPeriodicSync() after set delay ");
        clearTimeout(delayBeforePeriodicSync);
      }, 7000);

    }
    this.NetworkCheckListener();
    this.$RegisterSW();
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // document.addEventListener("swRegistered", this.sendBusinessDataToSW, {
    //   once: true,
    // });

    // SAVE_CURRENT_BUSINESS_TO_OBJECT_STORE().then(data => {
    //   console.log(data);
    // }).catch(err => console.log(err));

    // this.refreshModules();

    // if (navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //     if (this.refreshing) {
    //       return;
    //     }
    //     this.refreshing = true;
    //     window.location.reload();
    //   });
    // }

    // EventBus for notification
    eventBus.$on("trac-alert", (data) => {
      // Show notification
      this.alertDetails.alertMessage = data.message;
      this.alertDetails.showNotification = true;
    });
    eventBusAlternate.eventBus.$on("trac-alert", (data) => {
      // Show notification
      this.alertDetails.alertMessage = data.message;
      this.alertDetails.showNotification = true;
    });
  },
  computed: {
    getRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    transactingMerchantCheck(BID) {
      // TODO: should this function only run for admin accounts?
      if (BID && typeof BID === 'string') {
        const sessionKey = `transactionValueCheck_${BID}`
        const lastCheck = (GET_LOCAL_DB_DATA(sessionKey) || {timestamp : 0}).timestamp
        const timeLapseBeforeChecking = 2629800000 // one month in milliseconds
        const storeIds = ((GET_USER_DATA() || {}).stores || []).map(store => store.id).join(",")
        const now = Date.now()
        const oneMonthBack = now - timeLapseBeforeChecking
        const isDueForCheck = (now - lastCheck) > timeLapseBeforeChecking // has it been more than a month since last check?
        // debugger
        if(isDueForCheck) {
          const dateRange = {
            startDate: moment(now).format("YYYY-MM-DD"),
            endDate: moment(oneMonthBack).format("YYYY-MM-DD"),
          };
          const searchPayload = {
            dateRange,
            storeIds
          }
          this.$store.dispatch("FETCH_ALL_PAYMENTS_V2", searchPayload).then((result) => {
            const transactionValue = result.data.item.total_value_payments
            const isDeemedATransactingMerchant = transactionValue > 100000

            // debugger
            // a transacting merchant. Update MixPanel User Profile
            this.$SetPersonalAttribute({
              propName: 'is_transacting_merchant',
              propValue: isDeemedATransactingMerchant
            })
            SAVE_LOCAL_DB_DATA(sessionKey, {timestamp: Date.now()})
          })
        } else {
          
        }
      }
    },
    perfObserver(list, observer) {
      list.getEntries().forEach((entry) => {
        if (entry.duration > 0) {
          console.log(`${entry.name}'s duration: ${entry.duration}`);
          if (!this.loadTime) {
            this.trackAppLoad(entry.duration)
            this.loadTime = entry.duration
          }
        }
      });
    },
    checkKYCstatusForMixPanel(BID) {
      if (BID) {
        const sessionKey = `lastkyccheck_${BID}`
        const timeLapseBeforeChecking = 86400000 // one day
        const lastKycCheck = (GET_LOCAL_DB_DATA(sessionKey) || {timestamp : 0}).timestamp
        const now = Date.now()
        const timeElapsed = now - lastKycCheck
        const isDueForCheck = timeElapsed >= timeLapseBeforeChecking
        // debugger
        if (isDueForCheck) {
          this.$store.dispatch("VERIFY_KYC").then((result) => {
            const fetchedKycDetails = result.data || []
            const isFullyVerified = fetchedKycDetails.every((rule) => rule.status === "VERIFIED");
            const wasGivenOverride = fetchedKycDetails.some((rule) => rule.status === "OVERRIDE");

            if (isFullyVerified) {
              this.$SetPersonalAttribute({propName: "kyc_status", propValue: "true"})
            } else {
              this.$SetPersonalAttribute({propName: "kyc_status", propValue: "false"})
            }

            this.$SetPersonalAttribute({propName: "kyc_overidden", propValue: wasGivenOverride})
            SAVE_LOCAL_DB_DATA(sessionKey, {timestamp: Date.now()})
          })
        }
      }
    },
    trackAppLoad(duration) {
      this.$TrackEvent('TA_MERCHANT_APP_OPEN', {
        loadTime : duration,
        route: this.$route.path
      })
    },
    // clearStuff() {
    //   CLEAR_LIST();
    // },
    bootIntercom() {
      this.$intercom.boot({
        user_id: this.userId,
        name: this.name,
        email: this.email,
      });
      // this.$intercom.show();
    },
    setupMerchantTrackingForHotjar() {
      if (this.$isProduction) {
        const Business = GET_USER_BUSINESS_DATA();
        const userDetails = {
          businessId: Business.user_id || "N/A",
          businessName: Business.name || "N/A",
        };
        window.hj("identify", userDetails);
      }
    },
    setupMerchantTrackingForSentry() {
      const Business = GET_USER_BUSINESS_DATA();
      Vue.setupUserForSentryTracking({
        BussinessID : Business.user_id || "N/A",
        "Business Name": Business.name || "N/A",
        MERCHANT_APP_VERSION: process.env.VUE_APP_VERSION || "N/A"
      })
      // if (this.$isProduction) {
      //   const Business = GET_USER_BUSINESS_DATA();
      //   console.log("Business For Sentry Tracking: ", Business);
      //   // Sentry.configureScope((scope) => {
      //   //   scope.setTag("BussinessID", Business.user_id || "N/A");
      //   //   scope.setTag("Business Name", Business.name || "N/A");
      //   // });
      // }
    },
    async checkforUpgrade() {
      const routeName = window.location.href.split("#")[1];
      // Check for current route and only show update
      // for any route outside of login, signup, forgot password
      // this is so that we can redirect to dashboard before updating
      // (see SWController.Plugin.js -> getFilesForCaching())
      console.log("current route: ", routeName);
      const shouldCheckForUpdate = this.excludePageFromSWCheck(routeName);
      // debugger;
      if (shouldCheckForUpdate) {
        const registration = await navigator.serviceWorker.getRegistration();
        console.log("current sw registration: ", registration);
        if (registration) {
          registration.addEventListener("updatefound", () => {
            console.log("Service Worker update found!");
            this.showRefreshUI();
          });
          if (registration.waiting) {
            this.showRefreshUI();
          }
        }
      }
    },
    excludePageFromSWCheck(routeName = "") {
      const PageException = ["/login", "/signup", "/forgot-password"].includes(
        routeName
      );
      return PageException === false;
    },
    doUpgrade() {
      // this.refreshing = true;
      // const routeName = window.location.href.split('#')[1]

      // if(['/', '/dashboard'].includes(routeName)) {

      // }

      // // navigate to dashboard first...
      // this.$router.push({ name: "Dashboard" })
      // .then(() => {
      //     // ...Cache all files (Has to be done from /dashboard)...
      //     return this.getFilesForCaching()
      // })
      // .then(() => {
      //     // ...then reload the page
      //     console.log('caching complete!')
      //     // window.location.reload()
      // })

      // console.log('file Manifest: ', this.$cacheManifest)
      this.upgradeSW();

      // this.getFilesForCaching().then(() => {
      //   console.log('caching complete!')
      // })
    },
    sendBusinessDataToSW(e) {
      navigator.serviceWorker.controller.postMessage({
        business: GET_USER_BUSINESS_ID(),
      });
      // this.registration.postMessage({business: GET_USER_BUSINESS_DATA()});
    },
    refreshApp() {
      // Clear all data on localStorage
      // localStorage.clear();

      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    showRefreshUI() {
      // this.registration = e.detail;
      this.updateExists = true;
    },
    refreshModules() {
      const interval = 60 * 30;

      setInterval(() => {
        if (GET_USER_BUSINESS_DATA()) {
          // this.$store.dispatch("REFRESH_ALL_CUSTOMERS");
          // this.$store.dispatch("REFRESH_ALL_PRODUCTS");
          // this.$store.dispatch("REFRESH_SALES_HISTORY");
          // Take this out 1st of june........
          // this.$store.dispatch('AUTO_LOGIN');
        }
      }, interval * 1000);
    },
  },
  watch: {
    $route(to, from) {
      const routeName = to.fullPath;
      console.log("App.vue -> Watcher -> route ", routeName);
      this.$TrackEvent('TA_MERCHANT_APP_ROUTE', {
        route: routeName
      })
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.btn-gradient {
  background: linear-gradient(318.39deg, #0143aa 8.55%, #003283 90.68%);
}
.btn-gradient:focus {
  background: inherit;
}
.white-gradient {
  box-shadow: 0px 0px 12.9699px rgba(22, 120, 176, 0.12);
}
.smallest {
  font-size: 0.6rem !important;
}
.tiny {
  font-size: 0.55rem !important;
}
.very-tiny {
  font-size: 0.5rem;
}
.small-shadow {
  box-shadow: 0px 0px 11.4791px rgba(22, 120, 176, 0.12);
}
.big-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.auth-shadow {
  box-shadow: 0px 4px 20px rgba(22, 120, 176, 0.12);
}
input:-webkit-autofill {
  /* box-shadow: 0 0 0px 1000px #1b1b1b inset; */
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: black;
  font-family: "Montserrat", sans-serif !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Auto scroll
#app {
  overflow-anchor: none;
}
.VuePagination__pagination {
  display: flex;
}
.VuePagination__pagination-item {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 5px;
  margin: 0 3px;
  cursor: pointer;
  border: 1px solid #555454;
  color: #555454;
}
.VuePagination__pagination .active {
  color: #253b95;
  border: 1px solid #253b95;
}
.VuePagination__pagination a.active {
  border: none;
}
.VuePagination__pagination .disabled {
  color: #c0c0c0;
  cursor: not-allowed;
  border: 1px solid #c0c0c0;
}
.VuePagination__count {
  display: none;
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 40px;
  height: 40px;
}
.spinner .path {
  stroke: #93bfec;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #fcf9f9;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(177, 177, 177);
}
.input-focus:focus-visible {
  outline: 2px solid #253b95 !important;
}
.main_container {
  max-width: 1440px;
}
</style>
