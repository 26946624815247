<template>
  <div class="relative">
    <!-- For File upload view -->
    <div v-if="view === 'fileInput'" class="relative">
      <div class="border relative border-gray-200 rounded-md flex items-center">
      <div v-if="loading" class="bg-gray-700 smallest text-white text-center py-4 rounded-md bg-opacity-75 absolute top-0 left-0 right-0 bottom-0">
        Uploading...
      </div>
        <input
          ref="file"
          :id="randomId"
          @change="checkIfValid()"
          type="file"
          placeholder="Upload Banner"
          class="flex-1 hidden smallest p-4"
        />
        <label :for="randomId" class="flex-1 smallest p-4">
          <span v-if="!fileName">{{ placeholderText }}</span>
          <span v-else>{{ fileName }}</span>
        </label>
        <button v-if="!fileName" :class="{'button-bg' : loading, 'bg-gray-600' : !loading,  }" :disabled="!isValidFile" class="smallest text-white p-4 rounded-r-md">
          <span v-if="!loading">Upload</span>
          <span v-else>Uploading</span>
        </button>
        <label v-else :for="randomId" class="button-bg smallest text-white p-4 rounded-r-md">Change</label>
      </div>
      <div v-if="!loading" class="smallest text-gray-600 py-2">
        JPG or PNG, file size no more than 10MB
      </div>
      <div v-if="invalid" class="smallest text-red-600">Invalid Type</div>
    </div>
    <!-- End File upload view -->

    <!-- For document upload view -->
     <div v-if="view === 'documentInput'" class="relative">
      <div class="border-dashed flex flex-col gap-y-3 border-2 border-gray-400 p-4 mt-6 rounded-md cursor-pointer">
        <div class="flex flex-row relative">
          <div v-if="loading" class="bg-gray-700 smallest text-white text-center py-4 rounded-md bg-opacity-75 flex justify-center items-center absolute top-0 left-0 right-0 bottom-0">
            Uploading...
          </div>
          <div :class="{'invisible': loading}" class="flex-1 relative">
            <input
              ref="file"
              :id="randomId"
              @change="checkIfValid()"
              type="file"
              placeholder="Upload Banner"
              class="absolute top-0 left-0 w-full h-full smallest p-4 opacity-0 cursor-pointer"
            />
            <div class="flex flex-row items-center gap-x-2 w-full">
              <img class="w-4 h-4" src="@/assets/svg/upload-icon-up.svg" alt="">
              <p class="text-blue-800 font-bold text-xs mt-2 uppercase">{{ placeholderText }}</p>
            </div>
          </div>
          <div v-if="!fullUrl" :class="{'invisible': loading}" class="w-16 h-16 inline-block bg-gray-200">
          
          </div>
          <img v-if="fullUrl && !isPdf" :class="{'invisible': loading}" :src="fullUrl" class="w-16 h-16 inline-block bg-gray-200" />
          <div v-if="isPdf" class="w-16 h-16 inline-block bg-green-200">
            <img src="@/assets/green-tick.png" alt="">
          </div>
        </div>
        <div class="text-xs text-gray-500">File format - .jpg, .png, .pdf    |   File Size - Below 1 MB</div>
        <div v-if="invalid" class="smallest text-red-600">Invalid Type</div>
      </div>
     </div>
    <!-- End document upload view -->
  </div>
</template>

<script>
import { eventBus } from "../../eventBus";
export default {
  props: {
    placeholderText: {
      type: String,
      default: 'Select an Image'
    },
    view: {
      type: String,
      default: 'fileInput'
    },
    allowedTypes: {
      type: Array,
      default: () => ['image/png', 'image/jpeg']
    }
  },
  data() {
    return {
      invalid: false,
      loading: false,
      fileName: null,
      randomId: `uploadField-${Math.floor(Math.random() * 100)}`,
      fullUrl: '',
      extension: '',
    };
  },
  methods: {
    checkIfValid() {
      const fileDetails = this.$refs.file.files[0] || { type: "N/A" };
      // console.log(fileDetails)
      this.extension = fileDetails.type
      if (this.allowedTypes.includes(fileDetails.type)) {
        console.log("Valid Type");
        this.invalid = false;
        this.doUpload(fileDetails)
      } else {
        console.log("Invalid Type");
        this.invalid = true;
      }
    },
    doUpload(file) {
      this.loading = true;
      const payload = { file };
      this.fileName = file.name
      // debugger
      this.$store.dispatch("UPLOAD_FILE", payload).then((data) => {
        if (data.status) {
          this.loading = false;
          this.fullUrl = data.data
          this.$emit('uploadedFile', data.data)
        } else {
          this.loading = false;
          eventBus.$emit("trac-alert", {
            message: data.message
          });
        }
      });
    },
  },
  computed: {
    isValidFile() {
      return this.data;
    },
    isPdf() {
      return ['application/pdf'].includes(this.extension);
    },
    valueIsOkay() {
        if (this.bookingValue) {
            return true
        } else {
            return false
        }
    }
  },
};
</script>

<style lang="postcss" scoped>
.button-bg {
  background: #253B95;
}
</style>