import {
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
} from "../browser-db-config/localStorage";
import { apiUrl } from "../environment/environment";

export const ModuleConfig = {
  customers: {
    name: "customers",
    syncDetail(lastUpdate = 0, params = null) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "customers_unsynced",
          tableToSync: "customers",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: `${apiUrl}/v1/customers/syncnewofflinecustomers`,
          moduleGETAPIEndpoint: `${apiUrl}/v2/customers/businesses/${GET_USER_BUSINESS_ID()}${
            lastUpdate ? "?updated_at=" + lastUpdate : ""
          }`,
          offlineKeyValue: "offline_cust_id",
        },
        EVENT_TYPE: "BACKGROUND_SYNC",
      };
    },
  },
  payments: {
    name: "payments",
    syncDetail(lastUpdate = 0) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "payments_unsynced",
          tableToSync: "payments",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: ``,
          customPropertyRecords: "list_of_payments",
          moduleGETAPIEndpoint: `${apiUrl}/v1/payments/single/businesses/${GET_USER_BUSINESS_ID()}?batchsync=true`,
          offlineKeyValue: "offline_pay_id",
          isBatchedProcess: true
        },
        EVENT_TYPE: "DOWNLOAD_ONLY_SYNC",
      };
    },
  },
  products: {
    name: "products",
    syncDetail(lastUpdate = 0) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "products_unsynced",
          tableToSync: "products",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: `${apiUrl}/v1/customers/syncnewofflinecustomers`,
          moduleGETAPIEndpoint: `${apiUrl}/v2/businesses/${GET_USER_BUSINESS_ID()}/products${
            lastUpdate ? "?updated_at=" + lastUpdate : ""
          }`,
          offlineKeyValue: "offline_prod_id",
        },
        EVENT_TYPE: "BACKGROUND_SYNC",
      };
    },
  },
  categories: {
    name: "categories",
    syncDetail(lastUpdate = 0) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "categories_unsynced",
          tableToSync: "categories",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: `${apiUrl}/v1/customers/syncnewofflinecustomers`,
          moduleGETAPIEndpoint: `${apiUrl}/v2/businesses/${GET_USER_BUSINESS_ID()}/collections${
            lastUpdate ? "?updated_at=" + lastUpdate : ""
          }`,
          offlineKeyValue: "offline_category_id"
        },
        EVENT_TYPE: "DOWNLOAD_ONLY_SYNC",
      };
    },
  },
  stores: {
    name: "stores",
    syncDetail(lastUpdate = 0) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "stores_unsynced",
          tableToSync: "stores",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: ``,
          moduleGETAPIEndpoint: `${apiUrl}/v1/stores/businesses/${GET_USER_BUSINESS_ID()}${
            lastUpdate ? "?updated_at=" + lastUpdate : ""
          }`,
          offlineKeyValue: "offline_category_id",
          updateWithoutMeta: true
        },
        EVENT_TYPE: "DOWNLOAD_ONLY_SYNC",
      };
    },
  },
  pos: {
    name: "pos",
    syncDetail(lastUpdate = 0, params = null) {
      return {
        EVENT_PAYLOAD: {
          BusinessId: GET_USER_BUSINESS_ID(),
          nonSyncedTable: "pos_unsynced",
          tableToSync: "pos",
          authToken: GET_USER_TOKEN(),
          moduleAPIEndpoint: `${apiUrl}/v1/sales/syncsale/`,
          moduleGETAPIEndpoint: ``,
          offlineKeyValue: "offline_cust_id",
        },
        EVENT_TYPE: "UPLOAD_ONLY_SYNC",
      };
    },
  },
};
const OfflineConfig = { PeriodicSync: {} };
OfflineConfig.PeriodicSync[ModuleConfig.customers.name] = {
  frequency: 60000,
};

OfflineConfig.PeriodicSync[ModuleConfig.products.name] = {
  frequency: 80000,
};

export const GetOfflineConfigSettings = (moduleName = null) => {
  if (moduleName && typeof moduleName === "string") {
    return OfflineConfig.PeriodicSync[moduleName] || null;
  } else {
    console.warn(
      "config -> GetOfflineConfigSettings: moduleName not provided or string. returning null"
    );
    return null;
  }
};
