import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from '../../browser-db-config/indexedDB';
import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const state = {
	allCategories: null,
	allCategoriesWithSub: null,
};

const getters = {
	GET_ALL_CATEGORIES: (state) => state.allCategories,
	GET_ALL_CATEGORIES_WITH_SUB: (state) => state.allCategoriesWithSub,
	GET_RES_FEEDBACK: (state) => state.feedback,
};

const actions = {
	FETCH_ALL_CATEGORIES: async function (context) {
		// fetch from db (not-synced)

		// fetch from db
		// const offlineCategoriesDB = await CONNECT_TO_DB('offline_db_categories', 1, 'categories');
		let offlineCategories = await GET_OBJECT_STORE('offline_db_categories', 'categories');

		// Fetch from server
		const httpReq = {
			// url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections_with_sub`,
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections`,
			method: 'GET',
			authRequest: true,
		};

		HTTP_REQUEST(httpReq).then(httpRes => {
			offlineCategories = httpRes;
			// debugger
			if (httpRes.data) {
				// Save assets to indexDB
				// await ADD_DATA_TO_DB(offlineCategoriesDB, 'categories', offlineCategories);
				SAVE_OBJECT_STORE('offline_db_categories', 'categories', offlineCategories);
				context.commit('SET_UP_ALL_CATEGORIES', offlineCategories);
			}
		});

		if (!offlineCategories) {
			offlineCategories = await HTTP_REQUEST(httpReq);
		}

		context.commit('SET_UP_ALL_CATEGORIES', offlineCategories);
		return offlineCategories;
	},
	FETCH_ALL_CATEGORIES_WITH_SUB_CATEGORIES: async function (context) {
		// fetch from db (not-synced)

		// fetch from db
		// const offlineCategoriesWithSubDB = await CONNECT_TO_DB('offline_db_categories_with_sub', 1, 'categories_with_sub');
		// let offlineCategoriesWithSub = await GET_DB_DATA(offlineCategoriesWithSubDB, 'categories_with_sub');
		let offlineCategoriesWithSub = await GET_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub');


		// Fetch from server
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections_with_sub`,
			// url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections`,
			method: 'GET',
			authRequest: true,
		};

		HTTP_REQUEST(httpReq).then(httpRes => {
			offlineCategoriesWithSub = httpRes;
			// debugger
			if (httpRes.data) {
				// Save assets to indexDB
				// await ADD_DATA_TO_DB(offlineCategoriesWithSubDB, 'categories_with_sub', offlineCategoriesWithSub);
				SAVE_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub', offlineCategoriesWithSub);
			}
			context.commit('SET_UP_ALL_CATEGORIES_SUB', offlineCategoriesWithSub);
		});


		if (!offlineCategoriesWithSub) {
			offlineCategoriesWithSub = await HTTP_REQUEST(httpReq);
		}

		context.commit('SET_UP_ALL_CATEGORIES_SUB', offlineCategoriesWithSub);
		return offlineCategoriesWithSub;
	},
	CREATE_CATEGORY: async function (context, payload) {
		// fetch from db
		// const offlineCategoriesDB = await CONNECT_TO_DB('offline_db_categories', 1, 'categories');
		let offlineCategories = await GET_OBJECT_STORE('offline_db_categories', 'categories');

		// const offlineCategoriesWithSubDB = await CONNECT_TO_DB('offline_db_categories_with_sub', 1, 'categories_with_sub');
		let offlineCategoriesWithSub = await GET_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub');

		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			if (!offlineCategories) {
				offlineCategories = {
					data: []
				};
			}

			if (!offlineCategoriesWithSub) {
				offlineCategoriesWithSub = {
					data: []
				};
			}

			offlineCategories.data.push({ name: payload.name, count: 0 });

			//  Update DB with sub category
			offlineCategoriesWithSub.data.push({
				Subs: [],
				category: {
					business_id: GET_USER_BUSINESS_ID(),
					count: 0,
					created_at: (new Date()).toISOString(),
					name: payload.name,
					parent: "",
					qb_id: "",
					updated_at: (new Date()).toISOString(),
					wc_category_id: 0,
					wc_category_sync_status: false,
					_id: httpRes.data,
				}
			});


			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_categories', 'categories', offlineCategories);

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub', offlineCategoriesWithSub);
		}

		context.commit('SETUP_FEEDBACK', httpRes);
	},
	ADD_PRODUCT_TO_CATEGORY_V2: async function (context, payload) {
		// fetch from db
		// const offlineCategoriesDB = await CONNECT_TO_DB('offline_db_categories', 1, 'categories');
		let offlineCategories = await GET_OBJECT_STORE('offline_db_categories', 'categories');

		// const offlineCategoriesWithSubDB = await CONNECT_TO_DB('offline_db_categories_with_sub', 1, 'categories_with_sub');
		let offlineCategoriesWithSub = await GET_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub');

		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/collections/`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('SETUP_FEEDBACK', httpRes);
	},
	ADD_PRODUCT_TO_CATEGORY: async function (context, payload) {
		// fetch from db
		// const offlineCategoriesDB = await CONNECT_TO_DB('offline_db_categories', 1, 'categories');
		let offlineCategories = await GET_OBJECT_STORE('offline_db_categories', 'categories');

		// const offlineCategoriesWithSubDB = await CONNECT_TO_DB('offline_db_categories_with_sub', 1, 'categories_with_sub');
		let offlineCategoriesWithSub = await GET_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub');

		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/products/collections/`,
			method: 'PATCH',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		context.commit('SETUP_FEEDBACK', httpRes);

		if (httpRes.status) {
			offlineCategories.data = offlineCategories.data.map(cat => {
				// if (payload.products.includes())
				if (cat.name.toLowerCase() === payload.category.toLowerCase()) {
					cat.count = payload.products.length;
				}
				return cat;
			});

			offlineCategoriesWithSub.data = offlineCategoriesWithSub.data.map(cat => {
				// if (payload.products.includes())
				if (cat.category.name.toLowerCase() === payload.category.toLowerCase()) {
					cat.category.count = payload.products.length;
				}
				return cat;
			});

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_categories', 'categories', offlineCategories);

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_categories_with_sub', 'categories_with_sub', offlineCategoriesWithSub);
		}
	},
	DELETE_CATEGORY: async function (context, payload) {
		// fetch from db
		// const offlineCategoriesDB = await CONNECT_TO_DB('offline_db_categories', 1, 'categories');
		let offlineCategories = await GET_OBJECT_STORE('offline_db_categories', 'categories');

		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections/${payload.name
				}`,
			method: 'DELETE',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);

		if (httpRes.status) {
			offlineCategories.data = offlineCategories.data.filter(cat => cat.name !== payload.name);

			// Save assets to indexDB
			await SAVE_OBJECT_STORE('offline_db_categories', 'categories', offlineCategories);
		}
		context.commit('SETUP_FEEDBACK', httpRes);
		// if error occured
		if (!httpRes.status) {
			// Save as offline
		}
	},
	VALIDATE_CATEGORY_NAME: async function (context, payload) {
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/collections/names/?name=${payload.name
				}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		context.commit('SETUP_FEEDBACK', httpRes);
	},
};

const mutations = {
	SET_UP_ALL_CATEGORIES: (state, data) => {
		state.allCategories = data;
	},
	SET_UP_ALL_CATEGORIES_SUB: (state, data) => {
		state.allCategoriesWithSub = data;
	},
	SETUP_FEEDBACK: (state, data) => {
		state.feedback = data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
