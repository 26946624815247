<template>
  <div class="inline-block">
    <label
      class="checkbox flex items-center cursor-pointer"
      :class="{ 'checkbox--disabled': disableCheckbox }"
    >
      <span class="checkbox__input">
        <input
          @click="check"
          :checked="isChecked"
          type="checkbox"
          name="checkbox"
          :disabled="disableCheckbox"
          :class="disableCheckbox ? 'cursor-not-allowed' : ''"
        />
        <span class="checkbox__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              stroke-width="4"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
      <span class="text-gray-700">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disableCheckbox: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    value: {
      default: '',
    },
  },
  methods: {
    check(e) {
      this.$emit('check', { value: this.value, checked: e.target.checked });
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  font-size: 0.5rem;
  color: #253b95;
}

.checkbox__input {
  display: inline-grid;

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
}

.checkbox__control {
  cursor: pointer;
  display: inline-grid;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.25rem;
  border: 0.01rem solid #3c3a3a;
}

.checkbox__control svg {
  transition: all 0.1s;
  opacity: 0;
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.25rem;
  border-radius: 5px;
}

.checkbox__input input:checked + .checkbox__control svg {
  opacity: 1;
  background-color: #253b95;
  color: #fff;
}

.checkbox__input input:checkbox:disabled + .checkbox__control {
  color: #959495;
}

.checkbox--disabled {
  color: #959495;

  .checkbox__control {
    cursor: not-allowed;
  }
}
</style>
