import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import refreshModules from "./modules/refresh-modules";
import uploadfile from "./modules/upload-file";
import dashboard from "./modules/dashboard";
import paymentOptions from "./modules/settings/payment-options";
import bankAccounts from "./modules/settings/bank-accounts";
import usersRoles from "./modules/settings/users-roles";
import companyInfo from "./modules/settings/company-info";
import hardware from "./modules/hardware";
import stores from "./modules/stores";
import categories from "./modules/categories";
import registers from "./modules/registers";
import customers from "./modules/customers";
import inventoryTransfer from "./modules/inventory-control/inventory-transfer";
import printLabel from "./modules/inventory-control/print-label";
import stockAdjustment from "./modules/inventory-control/stock-adjustment";
import stockCount from "./modules/inventory-control/stock-count";
import invoices from "./modules/invoices";
import orders from "./modules/orders";
import pos from "./modules/pos";
import products from "./modules/products";
import salesHistory from "./modules/sales-history";
import payments from "@/store/modules/payments";
import insights from "./modules/insights";
import miscellaneous from "@/store/modules/settings/miscellaneous";
import fixedAccounts from "@/store/modules/fixed-accounts";
import customAccount from "@/store/modules/settings/custom-account";
import salesAgent from "@/store/modules/settings/sales-agents";
import beneficiaries from "./modules/beneficiary/beneficiaries";
import wallet from "./modules/wallet/wallet";
import settlements from "./modules/settlements/settlements";
import pin from "./modules/pin-management/pin";
import kyc from "./modules/kyc";
import loan from "./modules/loan";
import noona from "./modules/noona"


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  modules: {
    auth,
    refreshModules,
    uploadfile,
    paymentOptions,
    bankAccounts,
    companyInfo,
    dashboard,
    usersRoles,
    hardware,
    categories,
    customers,
    registers,
    inventoryTransfer,
    stockAdjustment,
    printLabel,
    stockCount,
    stores,
    invoices,
    orders,
    pos,
    products,
    salesHistory,
    insights,
    payments,
    miscellaneous,
    fixedAccounts,
    customAccount,
    kyc,
    beneficiaries,
    wallet,
    settlements,
    loan,
    pin,
    noona,
    salesAgent
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        "orders.orderLog",
        "miscellaneous.firstTimeUserStatus",
        "miscellaneous.selectPaymentModal",
        "miscellaneous.selectPaymentReference",
        "miscellaneous.fixedAccountModal",
        "miscellaneous.userDetails",
        "miscellaneous.kyc",
        "miscellaneous.fromFixedAccount",
        "pos.tickets",
      ],
    }),
  ],
});
