import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const state = {
	orderLog: null,
};

const getters = {};

const actions = {
	NOONA_CHECK_STATUS: async function(context, payload) {
		// Fetch from server
		const httpReq = {
			url: `/v1/noona/connect/${GET_USER_BUSINESS_ID()}/status`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_SYNC: async function(context, payload) {
		// Fetch from server
		const httpReq = {
			url: `/v1/noona/${GET_USER_BUSINESS_ID()}/sync`,
			method: 'PATCH',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_LINK: async function(context, payload) {
		// Fetch from server
		const httpReq = {
			url: `/v1/noona/stores/link`,
			method: 'POST',
			authRequest: true,
			body: payload
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_LOGIN: async function(context, payload) {
		// // Fetch from server
		const httpReq = {
			url: `/v1/noona/connect`,
			method: 'POST',
			authRequest: true,
			body: payload
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_PREFERENCES: async function(context, payload) {
		// Fetch from server
		const httpReq = {
			url: `/v1/noona/${GET_USER_BUSINESS_ID()}/preference`,
			method: 'PUT',
			authRequest: true,
			body: payload
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_SLUG: async function(context, payload) {
		// Post to server
		const httpReq = {
			url: `/v1/noona/${GET_USER_BUSINESS_ID()}/slug`,
			method: 'POST',
			authRequest: true,
			body: payload
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_TRANSACTIONS: async function(context, { dateRange, services, stores }) {
		// /v1/noona/:businessID/transactions?limit=10
		const servicesString = services.length > 0 ? `&service_id=${services.join('&service_id=')}` : ''
		const storeString = stores.length > 0 ? `&store_id=${stores.join('&store_id=')}` : ''
		// debugger
		// Post to server
		const httpReq = {
			url: `/v1/noona/${GET_USER_BUSINESS_ID()}/transactions?limit=10&end_date=${dateRange.end_date}&start_date=${dateRange.start_date}${servicesString}${storeString}`,
			method: 'GET',
			authRequest: true
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	NOONA_SERVICES: async function(context) {
		// /v1/noona/:businessID/transactions?limit=10
		// debugger
		// Post to server
		const httpReq = {
			url: `/v1/noona/${GET_USER_BUSINESS_ID()}/all-services`,
			method: 'GET',
			authRequest: true
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	}
};

const mutations = {
	// logOrders: (state, data) => {
	// 	state.orderLog = data;
	// },
};

export default {
	state,
	getters,
	actions,
	mutations,
};
