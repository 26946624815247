import ProgressBarComponent from "../components/ProgressBarComponent.vue";
import { SWDataObservable } from "../offline.store";
const SyncMonitorMixin = {
    components: { ProgressBarComponent },
    data() {
        return {
            syncManifestList: [],
            swdata: SWDataObservable,
            anyOngoing: false
        };
    },
    mounted() {
        this.updateManifest(this.syncManifest);
        // const soFar = this.$SWDataObservable
        // debugger
    },
    // computed: {
    //     swdata() {
    //         return SWDataObservable
    //     }
    // },
    methods: {
        convertToMegabytes(done = 0) {
            const megaBytes = done / 1000000
            return `${megaBytes.toFixed(2)} MB`
        },
        updateManifest(syncManifest = null) {
            // debugger
            if (syncManifest && typeof syncManifest === "object") {
                const keys = Object.keys(syncManifest);
                const ManifestList = keys.map((moduleItem) => syncManifest[moduleItem]);
                console.log("ManifestList", ManifestList);
                this.syncManifestList = ManifestList;
            }
        },
        calculateProgressPercentage(done = 0, total = 0) {
            const progress = Math.floor((done / total) * 100).toFixed(1)
            return `${progress}%`
        },
        checkIfOngoing() {
            const ongoingProcess = this.syncManifestList.filter(module => module !== null)
            const ongoing = ongoingProcess.length > 0
            // debugger
            return ongoing
        }
    },
    watch: {
        syncManifest(newValue, oldValue) {
            // debugger
            console.log('syncManifest: ',)
            this.updateManifest(newValue);
        },
        swdata: {
            handler(newValue, oldValue) {
                console.log('$SWDataObservable from mixin watcher: ', newValue)
                this.updateManifest(newValue.data);
                this.anyOngoing = this.checkIfOngoing()
                // debugger
            },
            deep: true
        },
    },
    computed: {
        // anyOngoing() {
        //     const ongoing = this.syncManifestList.map(item => item !== null)
        //     debugger
        //     return ongoing.length > 1
        // }
    },
}

export default SyncMonitorMixin