<template>
  <div
    @click="back"
    class="mt-5 inline-flex cursor-pointer items-center text-xs"
  >
    <svg
      class="mr-2 w-3 mb-px h-3"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00195 1.00147L1.00195 7.00147L7.00195 13.0015"
        stroke="#013E9E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    backAction: {
      type: Function,
    }
  },
  methods: {
    back() {
      if (this.backAction) {
        this.backAction();
      } else {
        this.$router.go(-1);
      }
    }
  }
}
</script>

<style>
</style>