import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

// extend('generic_required', value => {
//   // console.log(value);
//   // if (value.trim().length >= 0) {
//   //   return true;
//   // }

//   return 'This field is required.';
// });

extend('generic_required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true
});

extend('positive', value => {
  if (value >= 0) {
    return true;
  }

  return 'This {_field_} must be a positive number.';
});

extend('greaterthanzero', value => {
  if (value > 0) {
    return true;
  }

  return 'The {_field_} field must be a positive number.';
});

extend('onlynumbers', value => {
  // Regular expression
  const regex = /[0-9]/;
  return regex.test(value) ? true : 'The {_field_} accepts only numbers.';
});

extend('atleastonenumber', value => {
  // Regular expression
  const regex = /\d/;
  return regex.test(value) ? true : 'The {_field_} field must have atleast one number.';
});

extend('atleastoneuppercase', value => {
  // Regular expression
  const regex = /[A-Z]/;
  return regex.test(value) ? true : 'The {_field_} field must have atleast one uppercase letter.';
});

extend('atleastonelowercase', value => {
  // Regular expression
  const regex = /[a-z]/;
  return regex.test(value) ? true : 'The {_field_} field must have atleast one lowercase letter.';
});

extend('atleastonespecialcharacter', value => {
  // Regular expression
  const regex = /[-+_!@#$%^&.,?/><) (|;:"\\']/;
  return regex.test(value) ? true : 'The {_field_} field must have atleast one special character.';
});

extend('customerbirthday', value => {
  // (value);
  // const regex = new RegExp("/\d{1,2}/-\d{1,2}/");
  // (regex.test(value));
  // if (regex.test(value)) {
  //   return true;
  // }

  return true;

  return 'The {_field_} field must be of format DD-MM';
});

extend('minmax', {
  validate(value, { min, max }) {
    return +value >= min && +value <= max;
  },
  params: ['min', 'max'],
  message: 'The {_field_} field must be between {min} and {max}.'
});
  
export const exactLength = extend('exactLength', {
  validate(value, { length }) {
    return value.length == length;
  },
  params: ['length'],
  message: 'The {_field_} must be exactly {length} characters long.',
});