<template>
  <main :class="active ? 'overflow-x-hidden' : ''">
    <trac-topnav class="z-10" @toggle="toggle" />
    <div class="flex-none sm:flex">
      <SidebarV2 :active="active" class=" transition-all duration-500" />
      <!-- <Sidebar
        @toggle="active = !active"
        :class="!active ? 'w-24' : 'w-65'"
        class="
          h-screen
          overflow-y-auto
          mt-16
          pb-24
          bg-white
          shadow-md
          fixed
          transition-all
          duration-500
          ease-in-out
          hidden
          md:block
        "
      /> -->
      <!-- @toggle="toggle" -->
      <!-- <Sidebar
        :class="!sideBarActiveState ? 'w-0 hidden' : 'w-65'"
        class="
          h-screen
          overflow-y-auto
          mt-16
          pb-24
          bg-white
          shadow-md
          fixed
          transition-all
          duration-500
          ease-in-out
          md:hidden
        "
      /> -->
      <div :class="!active ? 'pl-4 md:pl-0 print:pl-0' : 'pl-0 flex-grow'" class="
          pt-16
          flex-1
          transition-all
          duration-500
          ease-in-out
          print-width
          max-w-screen-xl mx-auto
        ">
        <div class="flex justify-center pt-56 text-sm" v-if="user.IsFraud">
          <div class="flex flex-col justify-center items-center gap-3">
            <img src="./../assets/svg/alert_circle.svg" alt="" class="">
            <p class="mb-3 font-semibold">We noticed an issue with your ID. Kindly contact support to rectify.</p>
            <a class="bg-primaryBlue text-white text-xs py-4 px-6 rounded" href="https://tractionapps.co/contact/" target="_blank">Contact Support</a>
          </div>
        </div>
        <div v-else class="md:pl-5 md:pr-8 md:pt-6 md:pb-8 print-width">
          <trac-center-data v-if="appError" class="h-screen w-screen fixed top-0 left-0">
            <span class="px-4 text-xs">{{ message }} </span>
            <button class="text-primaryBlue text-xs cursor-pointer font-bold" @click="reloadPage">
              Click to Roload
            </button>
          </trac-center-data>
          <router-view v-else></router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Sidebar from "@/components/components/Sidebar";
import SidebarV2 from "@/components/components/Sidebar_v2";
import { eventBus } from "./../main";
import ScreenMixin from './../offline-module/mixins/Screensize';

export default {
  mixins: [ScreenMixin],
  components: {
    Sidebar,
    SidebarV2
  },
  data() {
    return {
      active: true,
      appError: false,
      message: null,
      errorType: null,
      reloadFunction: null,
      user: {},
    };
  },
  computed: {
    sideBarActiveState() {
      return this.active;
    },
  },
  created() {
    // EventBus for notification
    eventBus.$on("trac-error-alert", (payload) => {
      // this.appError = true;
      // this.errorType = payload.errorType;
      // this.message = payload.message;
      // this.reloadFunction = payload.reloadFunction;
    });
  },
  watch: {
    $route(x) {
      if (x) {
        if (screen.width < 640 && this.active) {
          this.active = !this.active;
        }
      }
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    toggle(event) {
      this.active = event.active;
    },
  },
};
</script>

<style scoped>
@media print {
  .print-width {
    /* padding: 0;
 margin: 0;
 width: 100%; */
  }

  .non-print-slip {
    display: none;
  }
}
</style>