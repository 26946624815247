import { GET_USER_BUSINESS_ID } from "../../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../../http-config/trac-http-req";


const actions = {
  FEATURE_TOGGLE: async function(context, payload) {
    const httpReq = {
      url: `/v1/toggle-features?business_id=${GET_USER_BUSINESS_ID()}&key=SALES_AGENT_CODE&scope=store&category=PAYMENT`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(
      httpReq
    );
   return httpRes;
  },
  GET_ALL_SALES_AGENT: async function (context, payload) {
    const httpReq = {
      url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/sales-agents`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(
      httpReq
    );
   return httpRes;
  },
  CREATE_SALES_AGENT: async function(context, payload) {
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/stores/${payload.store}/sales-agents`,
			method: 'POST',
			authRequest: true,
			body: payload,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
  },
  TOGGLE_FEATURES: async function (context, payload) {
    const httpReq = {
      url: `/v1/toggle-features`,
      method: "PUT",
      authRequest: true,
      body: payload,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes
  },
};

export default {
  actions,
};