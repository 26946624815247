import { GET_USER_BUSINESS_ID } from '../../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../../http-config/trac-http-req';

const state = {
  walletDetails: null,
};

const getters = {
  GET_WALLET_DETAILS: (state) => state.walletDetails,
};

const actions = {
  FETCH_TRANSACTION_LOGS: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/transactions/${payload.walletid}?transCount=&days=${payload.timelineDays}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      // context.commit("SETUP_WALLET_DETAILS", httpRes);
    }

    return httpRes;
  },
  FETCH_WALLET_DETAILS: async function (context) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/accountdetailsbyuserguid/${GET_USER_BUSINESS_ID()}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
      context.commit('SETUP_WALLET_DETAILS', httpRes);
    }

    return httpRes;
  },
  FETCH_TELCOMS: async function (context) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/telcoms`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }
    return httpRes;
  },
  FETCH_TELCOMS_DATA_INFO: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      // url: `/api/EWTraction/telcoms`,
      url: `/api/EWTraction/mobiledata/${payload.telcosName}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }
    return httpRes;
  },
  FETCH_WALLET_STATEMENT: async function (context, payload) {
    const httpReq = {
      url: `/api/EWTraction/transactionsbybusinessid/${GET_USER_BUSINESS_ID()}?startDate=${
        payload.startDate
      }&endDate=${payload.endDate}&download=csv`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }
    return httpRes;
  },
  FETCH_DATA_PLANS: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      // url: `/api/EWTraction/telcoms`,
      url: `/api/EWTraction/mobiledata/${payload.telcosName}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }
    return httpRes;
  },
  CREATE_WALLET: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/v1/payments/wallet/create`,
      method: 'POST',
      authRequest: true,
      body: payload,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }

    return httpRes;
  },
  TRANSFER_TO_BENEFICIARY: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/transferFunds/${payload.wallet.walletid}?deviceid=${payload.deviceID}`,
      method: 'POST',
      authRequest: true,
      body: payload.transferDetails,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }

    return httpRes;
  },
  MAKE_AIRTIME_REQUEST: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/product/airtime/${payload.walletDetails.walletid}`,
      method: 'POST',
      authRequest: true,
      body: payload.body,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }

    return httpRes;
  },
  MAKE_DATA_REQUEST: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/product/mobiledata/${payload.walletDetails.walletid}`,
      method: 'POST',
      authRequest: true,
      body: payload.body,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }

    return httpRes;
  },

  FETCH_PACKAGE: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/v1/billpayment/verificationcodes?serviceid=${payload}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  GET_REFERENCE: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/v1/billpayment/fetch/${payload}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  VERIFY_DEVICE: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/v1/billpayment/verification?serviceid=${payload.serviceID}&device=${payload.smartCardNumber}`,
      method: 'GET',
      authRequest: true,
    };

    if (payload.type) {
      httpReq.url += `&type=${payload.type}`;
    }

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_PROVIDERS: async function (context, payload) { 
    // Always fetching stores
    const httpReq = {
      url: `/v1/billpayment/providers`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  PAY_BILL: async function (context, payload) { 
    // Always fetching stores
    const httpReq = {
      url: `/v1/billpayment/purchase`,
      method: 'POST',
      authRequest: true,
      body: payload,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_WAITLIST_BY_ID: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/v2/savings/wait-list-by-businessId/${GET_USER_BUSINESS_ID()}`,
      method: 'GET',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  MAKE_WAITLIST_REQUEST: async function (context, payload) {
    const httpReq = {
      url: `/v2/savings/wait-list/${GET_USER_BUSINESS_ID()}`,
      method: 'POST',
      authRequest: true,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  MAKE_DATA_REQUEST: async function (context, payload) {
    // Always fetching stores
    const httpReq = {
      url: `/api/EWTraction/product/mobiledata/${payload.walletDetails.walletid}`,
      method: 'POST',
      authRequest: true,
      body: payload.body,
    };

    // Fetch online
    const httpRes = await HTTP_REQUEST(httpReq);

    if (httpRes.status) {
    }

    return httpRes;
  },
};

const mutations = {
  SETUP_WALLET_DETAILS: (state, data) => {
    state.walletDetails = data;
  },
  // SETUP_ALL_BENFICIARIES_BANKS: (state, data) => {
  //   state.allBanks = data;
  // },
  // UPDATE_ALL_BENEFICIARIES: (state, data) => {
  //   state.allBeneficiaries.data.push(data);
  // },
  // UPDATE_DELETED_BENEFICIARIES: (state, data) => {
  //   state.allBeneficiaries.data = state.allBeneficiaries.data.filter(beneficiary => beneficiary.beneficiaryGuid !== data.benficiaryID);
  // }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
