import { async } from 'q';
import {
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
} from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const actions = {
  //   CREATE_FIXED_ACCOUNT: async function (context, payload) {
  //     const httpReq = {
  //       url: `/v2/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}/customfixedaccount`,
  //       method: 'POST',
  //       authRequest: true,
  //       body: payload,
  //     };

  //     const httpRes = await HTTP_REQUEST(httpReq);
  //     return httpRes;
  //   },

  CREATE_FIXED_ACCOUNT: async (context, payload) => {
    const httpReq = {
      url: `/v2/payments/accounts/businesses/${GET_USER_BUSINESS_ID()}/customfixedaccount`,
      method: 'POST',
      authRequest: true,
      body: payload,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_FIXED_ACCOUNT: async function () {
    const httpReq = {
      url: `/v1/payments/fixedaccounts/businesses/${GET_USER_BUSINESS_ID()}`,
      method: 'GET',
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
  GET_BANKS: async function () {
    const httpReq = {
      url: `v1/payments/enabledvirtualaccountbanks`,
      method: 'GET',
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
