/**
 * checks the status of batched data inside the metaData object
 * returns the url for the next batch of records. otherwise,
 * it returns false
 * @param {*} metaData
 */
 export const batchSyncCheck = (
    metaData = {
      batched: {
        pageNumber: 1,
        totalPages: 0,
        nextUrl: '',
      },
    }
  ) => {
    let batchUrl = false;
    // debugger
    if (metaData.batched) {
      const hasFinishedAllBatches = metaData.batched.pageNumber >= metaData.batched.totalPages;
      console.log('has finished?', hasFinishedAllBatches);
      if (hasFinishedAllBatches) {
        batchUrl = false;
      } else {
        batchUrl = `https://${metaData.batched.nextUrl}`;
      }
    }
    return batchUrl
  };
  
  const testMetaData = {
    batched: {
      pageNumber: 1,
      totalPages: 10,
      nextUrl:
        'https://tractionapp-stage.herokuapp.com/v1/payments/single/businesses/5f9ff88923966500040c0600?page=2&numberOfItems=2000',
    },
  };
  
//   console.log(
//     'Url Parse Result ',
//     getUrlParamValue(
//       'https://tractionapp-stage.herokuapp.com/v1/payments/single/businesses/5f9ff88923966500040c0600?page=2&numberOfItems=2000', 'page'
//     )
//   );