import localForage from 'localforage';
import { ModuleConfig } from './config';

const shouldBeString = variable => typeof variable === 'string' || `${variable} should be string`
const shouldBeArray = variable => Array.isArray(variable) && typeof variable === 'object' || `${variable} should be an array`
const validateArguments = (rules = []) => {
    let valid = false
    let errors = []
    valid = rules.every(rule => rule === true)
    errors = rules.filter(rule => rule !== true)
    return [valid, errors.length > 0 ? errors : null]
}

const getKeysForEachModule = (moduleNames = [], BusinessId = null, LFH) => {
    // Assign it to variable and set it INDEXDB

    const formatedArrayOfPromises = [];
    if (Array.isArray(moduleNames) && moduleNames.length > 0) {
        for (let i = 0; i < moduleNames.length; i++) {
            formatedArrayOfPromises.push(
                LFH.createInstance({
                    name: BusinessId,
                    storeName: moduleNames[i],
                }).iterate(function (value, key, iterationNumber) {
                    if (iterationNumber < 3) {
                        // console.log([key, value]);
                    } else {
                        return [key, value];
                    }
                })
            );
            formatedArrayOfPromises.push(
                LFH.createInstance({
                    name: BusinessId,
                    storeName: `${moduleNames[i]}_unsynced`,
                }).iterate(function (value, key, iterationNumber) {
                    if (iterationNumber < 3) {
                        console.log([key, value]);
                    } else {
                        return [key, value];
                    }
                })
            );
            formatedArrayOfPromises.push(
                LFH.createInstance({
                    name: BusinessId,
                    storeName: `meta_data_${moduleNames[i]}`,
                }).iterate(function (value, key, iterationNumber) {
                    if (iterationNumber < 3) {
                        console.log([key, value]);
                    } else {
                        return [key, value];
                    }
                })
            );
        }

        // custom checking for non module table names
        formatedArrayOfPromises.push(
            LFH.createInstance({
                name: BusinessId,
                storeName: `syncsettings`,
            }).iterate(function (value, key, iterationNumber) {
                if (iterationNumber < 3) {
                    console.log([key, value]);
                } else {
                    return [key, value];
                }
            })
        );

        // custom checking for 'POS' table name.
        // this is because POS module only needs a table of unsynced records
        // and backend doesn't exist for this now

        // Commented out since POS + Periodic sync is implemented
        // formatedArrayOfPromises.push(
        //     LFH.createInstance({
        //         name: BusinessId,
        //         storeName: `pos_unsynced`,
        //     }).iterate(function (value, key, iterationNumber) {
        //         if (iterationNumber < 3) {
        //             console.log([key, value]);
        //         } else {
        //             return [key, value];
        //         }
        //     })
        // );
    }
    return formatedArrayOfPromises;
};

const prepDbFirst = async (BusinessId = null) => {
    const moduleNames = Object.keys(ModuleConfig)
    // debugger
    const [valid, errors] = validateArguments([
        shouldBeString(BusinessId),
        shouldBeArray(moduleNames)
    ])

    if (valid) {
        try {
            let LFH = localForage;
            LFH.setDriver([localForage.INDEXEDDB]);
            const allTableKeys = await Promise.all(getKeysForEachModule(moduleNames, BusinessId, LFH))
            console.log(`Processed keys from ${JSON.stringify(moduleNames)}`, allTableKeys)
            LFH = null
            console.log('LFH after process: ', LFH)
            return allTableKeys
        } catch (error) {
            throw new Error(`prepDB -> allTableKeys: ` + error)
        }
        // Promise.all(allTableKeys).then((data) => {

        // }).finally(() => {
        //     LFH = null
        //     console.log('LFH after process: ', LFH)
        // })
    } else {
        throw new Error(`prepDb: ` + JSON.stringify(errors))
    }
}

export default prepDbFirst