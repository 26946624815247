import { hasMetaRecord, hasSyncStatus } from './falseDestination';
/**
 * Structure:
*       BusinessID: {
 *          products: // metaData
 *          customers: // metaData
 *          categories: // metaData
 *          payments: // metaData
 *      }
 */
import {
  displayErrors,
  shouldBeString,
  validateArguments,
  shouldBeObject,
} from './fxnArgumentValidator';
export default class FallBackTracker {
  businessID;
  metaDataList;

  constructor(options = { BusinessID: null }) {
    const [valid, errors] = validateArguments([
      shouldBeString(options.BusinessID),
    ]);

    if (valid) {
      this.businessID = options.BusinessID;
      this.getMetaDataList();
    } else {
      throw new Error(`FallBackTracker() ` + displayErrors(errors));
    }
  }

  getMetaDataList() {
    this.metaDataList =
      JSON.parse(localStorage.getItem(`meta_data_${this.businessID}`)) || {};
    return this.metaDataList;
  }

  simulateFalseDestination() {
    const fakeData = {
      Request: {
        type: 'GET',
        url: 'tractionapp-stage.herokuapp.com/v2/customers/businesses/5c9ba6b2f81f57782def3728',
      },
      totalRecords: 0,
      lastUpdate: 7474774,
      recordsRemoved: null,
      syncStatus: 'syncing',
    };
    return fakeData;
  }

  clearMetaData(tableName) {
    const [valid, errors] = validateArguments([
      shouldBeString(tableName)
    ]);

    if (valid) {
      this.metaDataList[tableName] = null;
      localStorage.setItem(
        `meta_data_${this.businessID}`,
        JSON.stringify(this.metaDataList)
      );
    } else {
      throw new Error(`clearMetaData: ` + displayErrors(errors));
    } 
  }

  saveMetaData(tableName, payload) {
    const [valid, errors] = validateArguments([
      shouldBeString(tableName),
      shouldBeObject(payload),
    ]);

    if (valid) {
      this.metaDataList[tableName] = {
        ...payload,
        businessID: this.businessID,
      };
      localStorage.setItem(
        `meta_data_${this.businessID}`,
        JSON.stringify(this.metaDataList)
      );
    } else {
      throw new Error(`saveMetaData: ` + displayErrors(errors));
    }
  }

  getFallBackMetaData(moduleName) {
    const metaDataList = this.getMetaDataList();
    return metaDataList[moduleName];
  }

  isFalseDestination(moduleName) {
    // TODO: Check if moduleName and businessId match inside the metaData
    let falseDestination = false;
    return falseDestination;
  }
}
