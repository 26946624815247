import Vue from 'vue'
import { ModuleConfig } from './config'
const formatAsObject = (ModuleConfigObject = {})=>{
    const formatted = {}
    const arrayConfig = Object.keys(ModuleConfigObject)
    if(arrayConfig.length > 0) {
        arrayConfig.forEach((module) => {
            formatted[module] = false
        })
    }
    return formatted
}
const appState = Vue.observable({online: true})
export const SWDataObservable = Vue.observable({data: null})
export const cleanSlate = Vue.observable({moduleList: formatAsObject(ModuleConfig)})
export default appState