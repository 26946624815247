<template>
  <main>
    <label class="relative w-auto ">
      <input
        :disabled="disabled"
        class="border py-2 text-accentDark leading-6 mt-x pl-5 w-full text-xs rounded input-area bg-white"
        placeholder=" "
        @input="$emit('input', $event.target.value)"
        :value="value"
        :type="manual || type"
        :required="required"
        :autocomplete="autocomplete"
        :min="min"
        :max="max"
        data-test_id="input_test"
      />
      <span class="text-xs leading-5 text-primaryBlue">{{ placeholder }}</span>
      <div style="margin-top: -28px" class="absolute right-0 mr-10 ">
        <svg
          v-if="type === 'password' || manual === 'password'"
          @click="manual = 'string'"
          xmlns="http://www.w3.org/2000/svg"
          class="w-4 h-4  absolute "
          viewBox="0 0 612 612"
        >
          <path
            d="M609.608 315.426a19.767 19.767 0 000-18.853c-58.464-107.643-172.5-180.72-303.607-180.72S60.857 188.931 2.393 296.573a19.767 19.767 0 000 18.853C60.858 423.069 174.892 496.147 306 496.147s245.143-73.078 303.608-180.721zM306 451.855c-80.554 0-145.855-65.302-145.855-145.855S225.446 160.144 306 160.144 451.856 225.446 451.856 306 386.554 451.855 306 451.855z"
          />
          <path
            d="M306 231.67c-6.136 0-12.095.749-17.798 2.15 5.841 6.76 9.383 15.563 9.383 25.198 0 21.3-17.267 38.568-38.568 38.568-9.635 0-18.438-3.541-25.198-9.383a74.513 74.513 0 00-2.15 17.798c0 41.052 33.279 74.33 74.33 74.33s74.33-33.279 74.33-74.33S347.052 231.67 306 231.67z"
          />
        </svg>
        <svg
          v-if="specified == 'password' && manual == 'string'"
          @click="manual = 'password'"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 469.44 469.44"
          class="w-4 h-4  absolute "
        >
          <path
            d="M231.147 160.373l67.2 67.2.32-3.52c0-35.307-28.693-64-64-64l-3.52.32z"
          />
          <path
            d="M234.667 117.387c58.88 0 106.667 47.787 106.667 106.667 0 13.76-2.773 26.88-7.573 38.933l62.4 62.4c32.213-26.88 57.6-61.653 73.28-101.333-37.013-93.653-128-160-234.773-160-29.867 0-58.453 5.333-85.013 14.933l46.08 45.973c12.052-4.693 25.172-7.573 38.932-7.573zM21.333 59.253l48.64 48.64 9.707 9.707C44.48 145.12 16.64 181.707 0 224.053c36.907 93.653 128 160 234.667 160 33.067 0 64.64-6.4 93.547-18.027l9.067 9.067 62.187 62.293 27.2-27.093L48.533 32.053l-27.2 27.2zM139.307 177.12l32.96 32.96c-.96 4.587-1.6 9.173-1.6 13.973 0 35.307 28.693 64 64 64 4.8 0 9.387-.64 13.867-1.6l32.96 32.96c-14.187 7.04-29.973 11.307-46.827 11.307-58.88 0-106.667-47.787-106.667-106.667 0-16.853 4.267-32.64 11.307-46.933z"
          />
        </svg>
      </div>
    </label>
  </main>
</template>

<script>
import DisableAutocomplete from "vue-disable-autocomplete";
export default {
  props: [
    "placeholder",
    "disabled",
    "value",
    "type",
    "required",
    "specified",
    "autocomplete",
    "min",
    "max",
  ],
  data() {
    return {
      manual: null,
    };
  },
};
</script>

<style scoped>
span {
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.input-area:focus + span,
.input-area:not(:placeholder-shown) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%) translateX(-30px);
  left: 1.6rem;
  top: -0.45rem;
  background: white;
  padding: 0 5px;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.input-area:focus + .input-area:-webkit-autofill + span {
  opacity: 0;
}
.input-area:-webkit-autofill .input-area:placeholder-shown {
  opacity: 0 !important;
}
</style>
