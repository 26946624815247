<template>
  <div class="">
    <div class="mx-auto">
      <form autocomplete="off">
        <div class="flex justify-center">
          <input
            class="border w-10 text-center font-bold p-2 inline-block mx-4 rounded-md"
            maxlength="1"
            autocomplete="false"
            accept="numbers"
            @keyup="enterNumber"
            type="password"
            v-model.trim="pin1"
          />
          <input
            class="border w-10 text-center font-bold p-2 inline-block mx-4 rounded-md"
            maxlength="1"
            autocomplete="false"
            accept="numbers"
            @keyup="enterNumber"
            type="password"
            v-model.trim="pin2"
          />
          <input
            class="border w-10 text-center font-bold p-2 inline-block mx-4 rounded-md"
            maxlength="1"
            autocomplete="false"
            accept="numbers"
            @keyup="enterNumber"
            type="password"
            v-model.trim="pin3"
          />
          <input
            class="border w-10 text-center font-bold p-2 inline-block mx-4 rounded-md"
            maxlength="1"
            autocomplete="false"
            accept="numbers"
            @keyup="enterNumber"
            type="password"
            v-model.trim="pin4"
          />
        </div>
      </form>
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-3 shadow-md p-4 my-6"
        v-if="transactionData"
      >
        <p class="text-xs font-bold">PAY</p>
        <div class="flex justify-end">
        </div>
        <div class="flex items-center text-xs">
          <img
            width="60"
            class="p-2"
            :src="transactionData.service.Logo"
            alt=""
          />
          <div class="">
            <p class="font-bold">(+234) {{ transactionData.phoneNumber }}</p>
            <span class="text-gray-600 uppercase">{{
              transactionData.service.telcosName
            }}</span>
          </div>
        </div>
        <div class="flex flex-col items-end justify-center">
          <p class="text-xs">Amount to top-up</p>
          <p
            class="text-lg font-extrabold"
            v-if="transactionData.transactionType === 'airtime'"
          >
            {{ transactionData.planSelected | formatPrice(false) }}
          </p>
          <p class="text-xs font-extrabold" v-else>
            {{ transactionData.planSelected.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["transactionData", "selectedNetwork", "walletDetails"],
  data() {
    return {
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
    };
  },
  computed: {
    checkPin() {
      return this.pin1 + this.pin2 + this.pin3 + this.pin4;
    },
  },
  watch: {
    checkPin(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitOTP();
      }
    },
  },
  methods: {
    enterNumber(e) {
      console.dir(e);
      const target = e.target;

      if (
        target.nextSibling &&
        target.nextSibling.localName === "input" &&
        target.value.length > 0
      ) {
        target.nextSibling.focus();
      } else if (
        target == target.parentElement.lastChild &&
        target.value.length > 0
      ) {
        target.blur();
      }
    },
    emitOTP() {
      this.$emit("otp-filled", this.checkPin);
    },
  },
};
</script>
