<template>
  <div>
    <div class="bg-white big-shadow py-4 px-6">
      <h3 class="text-lg my-4 font-bold">{{ sectionTitle }}</h3>
      <div class="flex items-center gap-x-4 border-b-2 border-gray-300">
        <div
          class="text-sm cursor-pointer -mb py-2"
          style="margin-bottom: -2px"
          :class="{ selected: link.selected, unselected: !link.selected }"
          v-for="(link, index) in subLinks"
          :key="index"
          @click="selectTab(index)"
          :data-test="link.dataAttribute"
        >
          {{ link.name }}
        </div>
      </div>
    </div>

    <router-view name="tabbedView" :class="getRouterViewClassString"></router-view>
  </div>
</template>

<script>
export default {
  created() {
    const enteredPath = this.$route.path
    const subLinkIndex = this.subLinks.findIndex(link => link.path === enteredPath)
    if (subLinkIndex > -1) {
      this.selectTab(subLinkIndex)
    } else {
      this.subLinks[0].selected = true
      this.selectTab()
    }
  },
  props: {
    subLinks: {
      type: Array,
      default: () => {
        return []
      }
    },
    sectionTitle: {
      type: String,
      default: 'Page Title Here'
    }
  },
  computed: {
    getCurrentTabIndex() {
      const index = this.subLinks.findIndex((tab) => tab.selected === true);
      return index > -1 ? index : 0;
    },
    getRouterViewClassString() {
      return this.subLinks[this.getCurrentTabIndex].routerWindowClassString || ''
    }
  },
  methods: {
    selectTab(index = 0) {
      this.resetTabs();
      this.subLinks[index].selected = true;
      this.openSubPage(this.subLinks[index].path)
    },
    resetTabs() {
      this.subLinks.forEach((item) => (item.selected = false));
    },
    openSubPage(path) {
      // this.$router.push({ path });
      this.$router.replace({ path });
    }
  },
};
</script>

<style lang="postcss" scoped>
.unselected {
  @apply text-gray-600;
}
.selected {
  color: rgba(1, 53, 136, 1);
  @apply font-bold border-b-2 border-blue-700;
}
</style>