import axios from "axios";
import store from "./../store/index";
import router from './../router/index';
import { apiUrl } from "./../environment/environment";
import { GET_LOCAL_DB_DATA, GET_USER_TOKEN } from "../browser-db-config/localStorage";
import { eventBus } from "../../src/eventBus";

const config = {
  baseURL: apiUrl
};

const HTTP_CLIENT = new axios.create(config);

const authInterceptor = (config) => {
  return config;
};

const loggerInterceptor = (config) => {
  return config;
};

const tokenInterceptor = async (config) => {
  if (config.url !== '/v1/auth/refresh/token') {
    const tokenDate = new Date(GET_LOCAL_DB_DATA('auth-token-date'));
    const hours = Math.abs((new Date()).getTime() - tokenDate.getTime()) / 3600000;

    if (hours > 23 && GET_USER_TOKEN()) {
      await store.dispatch('AUTO_LOGIN');
      config.headers.Authorization = `Bearer ${GET_USER_TOKEN()}`;
    }
  }
  return config;
};

// const showError = error => {
//   eventBus.$emit('trac-error-alert', {
//     message: error.message,
//   });
// }

HTTP_CLIENT.interceptors.request.use(authInterceptor);
HTTP_CLIENT.interceptors.request.use(loggerInterceptor);
HTTP_CLIENT.interceptors.request.use(tokenInterceptor);

HTTP_CLIENT.interceptors.response.use(
  response => {
    // console.log(response);
    // if (response.status !== 200) {
    //   eventBus.$emit('trac-error-alert', {
    //   });
    // }
    return response;
  },
  error => {
    eventBus.$emit('trac-alert', {
      message: error.response.data.message
    });
    
    // console.log(error);
    // console.log(error.response);

    const user = GET_LOCAL_DB_DATA('traction-app-user-data');
    const business = GET_LOCAL_DB_DATA('traction-app-current-user-business-data');

    if (error.response.status === 401) { // || error.response.data.code === 'AU001'
      // localStorage.removeItem('traction-app-user-data');
      // localStorage.removeItem('traction-app-current-user-business-data');
      // console.error('error 401', response)
      router.push({ name: 'Login' });
    } else if (!user || !business) {
      router.push({ name: 'Login' });
    };

    return Promise.reject(error);
  }
);

export { HTTP_CLIENT };
