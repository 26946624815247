import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const actions = {
  GET_LOAN_STATUS: async function (context, payload) {
    const httpReq = {
      url: `/v1/loan/eligibility`,
      method: "GET",
      authRequest: true,
    };
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

export default {
  actions,
};
