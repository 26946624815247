<template>
  <div class="relative" v-click-outside="close">
    <div class="flex">
      <button
        @click="
          toggleFilterList = !toggleFilterList;
          toggleDatePicker = false;
        "
        class="border py-3 flex group items-center px-5 hover:border-blue-700 hover:text-blue-700 cursor-pointer border-primaryBlue text-xs rounded-md text-primaryBlue font-medium"
      >
        {{ label }}
        <div>
          <svg
            class="w-4 h-4 ml-4 group-hover:text-blue-700"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
              fill="#253B95"
            />
          </svg>
        </div>
      </button>
    </div>
    <div
      v-show="toggleFilterList"
      class="bg-white rounded-md shadow-md border border-gray-200 absolute right-0 w-65"
    >
      <div v-if="dateMode && !multiSelect">
        <div class="font-medium text-sm title py-3 px-3">{{ label }}</div>
        <div class="grid grid-cols-2 gap-y-3 py-3">
          <div class="col-span-1 px-3 flex items-center gap-x-1">
            <input
              name="datePick"
              id="today-date-range"
              @change="selectDateRange('today')"
              type="radio"
            />
            <span class="text-xs">Today</span>
          </div>
          <div class="col-span-1 px-3 flex items-center gap-x-1">
            <input
              name="datePick"
              id="yesterday-date-range"
              @change="selectDateRange('yesterday')"
              type="radio"
            />
            <span class="text-xs">Yesterday</span>
          </div>
          <div class="col-span-1 px-3 flex items-center gap-x-1">
            <input
              name="datePick"
              id="lastweek-date-range"
              @change="selectDateRange('lastweek')"
              type="radio"
            />
            <span class="text-xs">Last Week</span>
          </div>
          <div class="col-span-1 px-3 flex items-center gap-x-1">
            <input
              name="datePick"
              id="lastmonth-date-range"
              @change="selectDateRange('lastmonth')"
              type="radio"
            />
            <span class="text-xs">Last Month</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="font-medium text-sm title py-3 px-3">{{ label }}</div>
        <div class="grid grid-cols-2 gap-y-3 py-3">
          <div
            v-for="(item, index) in multiSelectOptions"
            :key="index"
            class="col-span-1 px-3 flex items-center gap-x-1"
          >
            <input
              @change="selectOption(index)"
              name="optionPick"
              type="checkbox"
            />
            <span class="text-xs">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import ClickOutside from "vue-click-outside";
export default {
  data() {
    return {
      toggleFilterList: false,
      toggleDatePicker: false,
      showDate: true,
      multiSelectOptions: [],
      internalLabel: '',
      dateRange: {
        start: "",
        end: "",
      },
    };
  },
  directives: {
    ClickOutside,
  },
  watch: {
    multiSelectList(newValue, oldValue) {
      console.log(`new value -> ${this.label}`, newValue)
      this.multiSelectOptions = newValue.map((item) => {
        return { ...item, selected: false };
      });
    },
  },
  mounted() {
    console.log("list: ", this.multiSelectOptions);
  },
  computed: {
    selectedMultiListOptions() {
      return this.multiSelectOptions.filter((item) => item.selected === true);
    },
  },
  methods: {
    close() {
      this.toggleFilterList = false;
      this.toggleDatePicker = false;
    },
    selectOption(index) {
      this.multiSelectOptions[index].selected =
        !this.multiSelectOptions[index].selected;
      this.$set(
        this.multiSelectOptions,
        "index",
        !this.multiSelectOptions[index].selected
      );
      const trimed = this.selectedMultiListOptions.map((item) => {
        return { id: item.id, name: item.name };
      });
      this.$emit("multiListEmit", trimed);
    },
    selectDateRange(range = null) {
      if (range === "today") {
        const start = `${DateTime.now()
          .toUTC()
          .startOf("day")
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        const end = `${DateTime.now()
          .toUTC()
          .endOf("day")
          .startOf("second")
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        this.internallabel = range;
        this.dateRange.start = start;
        this.dateRange.end = end;
        this.toggleFilterList = false;
        this.emitDateRange();
      } else if (range === "yesterday") {
        const start = `${DateTime.now()
          .toUTC()
          .startOf("day")
          .startOf("second")
          .minus({ months: 0, days: 1 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        const end = `${DateTime.now()
          .toUTC()
          .endOf("day")
          .startOf("second")
          .minus({ months: 0, days: 1 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        this.internallabel = range;
        this.dateRange.start = start;
        this.dateRange.end = end;
        this.toggleFilterList = false;
        this.emitDateRange();
      } else if (range === "lastmonth") {
        const start = `${DateTime.now()
          .toUTC()
          .startOf("month")
          .minus({ months: 1, days: 0 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        const end = `${DateTime.now()
          .toUTC()
          .endOf("month")
          .endOf("day")
          .startOf("second")
          .minus({ months: 1, days: 0 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        this.internallabel = range;
        this.dateRange.start = start;
        this.dateRange.end = end;
        this.toggleFilterList = false;
        this.emitDateRange();
      } else if (range === "lastweek") {
        const start = `${DateTime.now()
          .toUTC()
          .startOf("day")
          .minus({ months: 0, days: 7 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        const end = `${DateTime.now()
          .toUTC()
          .endOf("day")
          .startOf("second")
          .minus({ months: 0, days: 1 })
          .toISO({ suppressMilliseconds: true, includeOffset: false })}.00`;
        this.internallabel = range;
        this.dateRange.start = start;
        this.dateRange.end = end;
        this.toggleFilterList = false;
        this.emitDateRange();
      }
      // debugger
    },
    emitDateRange() {
      this.$emit("dateChange", this.dateRange);
    },
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: true,
    },
    dateMode: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Select a Filter",
    },
    multiSelectList: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
  },
};
</script>

<style lang="postcss" scoped>
.title {
  background: #f7f6f680;
}
</style>