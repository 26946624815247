import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "./utils/validations";
import "./utils/components";
import "./utils/filters";
import "./assets/styles/index.css";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueTrip from "vue-trip";
import VueCarousel from "vue-carousel";
import dualSyncMessage from "./offline-module/components/dualSyncMessage"
import SalesIQ from "./zoho/sales_iq";
import * as Sentry from '@sentry/vue'
import Hotjar from '@hotjar/browser';
import sentryPlugin from "./error-reporting/sentry.plugin";
import VueIntercom from '@mathieustan/vue-intercom';
// import FingerPrintPlugin from '../src/security-module/fingerprinting'
import { fpjsPlugin } from '@fingerprintjs/fingerprintjs-pro-vue-v2';

const isProduction = process.env.NODE_ENV === 'production'

import ObservabilityPlugin from './ObservabilityPlugin'
const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN
Vue.use(ObservabilityPlugin, { isProduction, PROJECT_TOKEN: mixpanelToken, APP_VERSION: process.env.VUE_APP_VERSION})

if(isProduction) {
  console.log = () => {}
  console.info = () => {}
  console.warn = () => {}
}


Vue.use(VueIntercom, { appId: 'ddmdsstk' })
Vue.use(fpjsPlugin, {
  loadOptions: {
    apiKey: "XfIQaQbe8VYoYce0HWyH"
  },
});

// Makes Sentry only initiate in production environment
if (isProduction) {
  // Setup Sentry
  // Sentry.init({
  //   Vue: Vue,
  //   dsn: 'https://2e9122eaefd746258bc366b7920c2ed9@o1270281.ingest.sentry.io/6632235'
  // })

  // Setup HotJar
  // HotJar Tracker for recording user behaviour
  const siteId = 3120433;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  
} else {
  console.log('Development mode detected. Not initiating HotJar')
}

// fetch('https://version.tractionapps.co/.netlify/functions/version', {
//   method: 'GET'
// }).then((resp)=>{
//   return resp.json()
// }).then((version) => {
//   console.log('Version Manifest: ', version)
//   console.log('Build Version Number: ', process.env.VUE_APP_VERSION)
//   const isLatestVersion = version.MERCHANT_APP_VERSION === process.env.VUE_APP_VERSION
//   if (isLatestVersion) {
//     console.log('Latest Version. Setup Sentry')
//   } else {
//     console.warn('Version mismatch at Version Manifest')
//   }
// })

Vue.use(sentryPlugin, {isProduction, sentryDsn: 'https://2e9122eaefd746258bc366b7920c2ed9@o1270281.ingest.sentry.io/6632235'})


// Offline manager
import SWController from './offline-module/SWController.Plugin'
Vue.use(SWController, {isProduction})

require("vue-trip/dist/vue-trip.css");
Vue.prototype.$toggleBodyClass = (addRemoveClass, className) => {
  const elBody = document.body;

  if (addRemoveClass === "addClass") {
    elBody.classList.add(className);
  } else {
    elBody.classList.remove(className);
  }
};

// Vue.use(SalesIQ, {widgetcode: '9e2b52aaaef0869c820ab6637cfea5aa6edc23327f3e55cc4f8599871ca7b229d26184bd399c39c25760b4f3715a450b', domain: 'https://salesiq.zoho.com/widget'});
Vue.use(VueCarousel);
Vue.use(VueTrip);
Vue.use(PerfectScrollbar);
Vue.component("zingchart", zingchartVue);
Vue.component('trac-dual-sync', dualSyncMessage);
Vue.use(Donut);
Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    // document.body.style.zoom = '95%'
  },
}).$mount("#app");
