/**POLYFIL ARRAY.EVERY**/
if (!Array.prototype.every) {
    Array.prototype.every = function (callbackfn, thisArg) {
        'use strict';
        var T, k;

        if (this == null) {
            throw new TypeError('this is null or not defined');
        }

        // 1. Let O be the result of calling ToObject passing the this
        //    value as the argument.
        var O = Object(this);

        // 2. Let lenValue be the result of calling the Get internal method
        //    of O with the argument "length".
        // 3. Let len be ToUint32(lenValue).
        var len = O.length >>> 0;

        // 4. If IsCallable(callbackfn) is false, throw a TypeError exception.
        if (typeof callbackfn !== 'function' && Object.prototype.toString.call(callbackfn) !== '[object Function]') {
            throw new TypeError();
        }

        // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 1) {
            T = thisArg;
        }

        // 6. Let k be 0.
        k = 0;

        // 7. Repeat, while k < len
        while (k < len) {

            var kValue;

            // a. Let Pk be ToString(k).
            //   This is implicit for LHS operands of the in operator
            // b. Let kPresent be the result of calling the HasProperty internal
            //    method of O with argument Pk.
            //   This step can be combined with c
            // c. If kPresent is true, then
            if (k in O) {
                var testResult;
                // i. Let kValue be the result of calling the Get internal method
                //    of O with argument Pk.
                kValue = O[k];

                // ii. Let testResult be the result of calling the Call internal method
                // of callbackfn with T as the this value if T is not undefined
                // else is the result of calling callbackfn
                // and argument list containing kValue, k, and O.
                if (T) testResult = callbackfn.call(T, kValue, k, O);
                else testResult = callbackfn(kValue, k, O)

                // iii. If ToBoolean(testResult) is false, return false.
                if (!testResult) {
                    return false;
                }
            }
            k++;
        }
        return true;
    };
}
/**END POLYFIL ARRAY.EVERY**/
import Localbase from 'localbase'
import {hasMetaRecord, hasSyncStatus, falseDestinationRemoval} from './falseDestination'
import { displayErrors, shouldBeString, validateArguments } from './fxnArgumentValidator';
export default class OfflineManager {
    BusinessId
    db

    constructor(options = { BusinessId: '' }) {
        if (options.BusinessId) {
            this.BusinessId = options.BusinessId
            this.db = new Localbase(options.BusinessId)
        }
    }

    async getRecordsUsingKeys(arrayOfKeys = [], tableName = '') {
        let processed = []
        if (Array.isArray(arrayOfKeys) && typeof tableName === 'string') {
            // Reformat the array of records into an array of Promises 
            processed = await Promise.all(arrayOfKeys.map(key => this.db.collection(tableName).doc(key).get()))
        } else {
            throw new Error('parameter mismatch: arrayOfKeys must be array and tableName must be string')
        }
        return processed
    }

    clearTable(tableName = '') {
        const [valid, errors] = validateArguments(
            [
                shouldBeString(tableName),
            ]
        )
        if (valid) {
            const clearSyncedTable = this.db.collection(tableName).set([])
            const clearUnSyncedTable = this.db.collection(`${tableName}_unsynced`).set([])
            const clearMetaDataTable = this.db.collection(`meta_data_${tableName}`).set([])
            return Promise.all([clearSyncedTable, clearUnSyncedTable, clearMetaDataTable])
        } else {
            throw new Error(`clearTable: ` + displayErrors(errors))
        }
    }

    isMoreThanTimePeriod(lastSyncTime = 0, timePeriod = 86400000) {
        if(lastSyncTime && !isNaN(lastSyncTime)) {
            const timeNow = new Date(Date.now()).getTime()
            const lastSyncTimeInMiliseconds = lastSyncTime * 1000
            const timeDifference = Math.abs(timeNow - lastSyncTimeInMiliseconds)
            const isMore = timeDifference > timePeriod || false
            return isMore
        } else {
            this.handleError('isMoreThanTimePeriod', 'lastSyncTime is either 0 or not a number')
        }
    }

    /**
     * Checks for the last sync (lastUpdate) in the meta data table for the module name
     * if the time difference between the last sync and right now is more than timePeriod
     * (24hrs), it returns a true.
     * 
     * This function returns either true or false, and is used to decide if to sync with offline via
     * the Vue App or Do it via the service worker
     * @param {*} tableName the name of the table
     * @param {*} timePeriod the timePeriod to measure against. default is 1 day in milliseconds
     */
    async lastSyncQualifiesForBackgroundSync(tableName = null, timePeriod = 86400000) {
        if (tableName && timePeriod) {
            let qualifies = false
            const lastUpdateExists = (metaData) => {
                if (metaData) {
                    return metaData.lastUpdate && !isNaN(metaData.lastUpdate) || false
                } else {
                    return false
                }
            }
            const [data, error] = await this.handlePromise(this.getMetaData(tableName))
            if (!error && data && lastUpdateExists(data)) {
                const testForFalse = Date.now() - 100000
                const lastSync = data.lastUpdate
                // then do the millisecond difference from last sync to now
                // against a day
                // debugger
                qualifies = this.isMoreThanTimePeriod(lastSync, timePeriod)
            }
            return qualifies
        } else {
            this.handleError('lastSyncQualifiesForBackgroundSync', `parameters not provided accurately: ${JSON.stringify(arguments)}`)
        }
    }

    generateUniqueID(l) {
        let text = '';
        let charList = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < l; i++) {
          text += charList.charAt(Math.floor(Math.random() * charList.length));
        }
        return text
      }

    checkArguments(argsToCheckFor = [], suppliedArgs = [], functionName = null) {
        const errors = []

        switch (argsToCheckFor) {
            case !this.isArray(argsToCheckFor):
                errors.push('argsToCheckFor is not an array')
                break;

            case argsToCheckFor.length < 1:
                errors.push('argsToCheckFor array is empty')
                break;

            default:
                break;
        }

        switch (suppliedArgs) {
            case !this.isArray(suppliedArgs):
                errors.push('suppliedArgs is not an array')
                break;

            case suppliedArgs.length < 1:
                errors.push('suppliedArgs is not an array')
                break;

            default:
                break;
        }

        if (!functionName) {
            errors.push('functionName not supplied')
        }

        if (errors.length > 0) {
            let errorString = ''
            errors.forEach((msg) => {
                errorString += `${msg} \n`
            })
            this.handleError(functionName, errorString)
        } else {
            let functionParametersExists = false
            const missingParameters = []
            // debugger
            suppliedArgs.forEach((parameter) => {
                console.log('parameter to check for: ', parameter)
                if (argsToCheckFor.includes(parameter)) {
                    functionParametersExists = true
                } else {
                    functionParametersExists = false
                    missingParameters.push(parameter)
                }
            })

            if (functionParametersExists) {
                console.log(`${functionName}: CheckArguments checks out`)
                return true
            } else {
                this.handleError(`${functionName} -> checkArguments: Missing Parameters:`, missingParameters)
            }
        }
    }

    async readSingleton(tableName = null) {
        if (tableName) {
            // read from db.
            const [data, error] = await this.handlePromise(this.getAll(tableName))
            if (error) {
                this.handleError('readSingleton', `error on reading: ${error}`, true)
            } else {
                console.log('data from singleton: ', data)
                if (data.length <= 1) {
                    // check if lenght is exactly 1
                    return data[0]
                } else {
                    this.handleError('readSingleton', `reading data from table ${this.BusinessId} -> '${tableName}' as a singleton is returning more than one result. Returning an empty object`, false)
                    console.info(`Returning Multiple Entry Table for ${this.BusinessId}:`)
                    // console.table(data)
                    return {}
                }
            }
            // if lenght is exactly 1, select it and return as object
            // if lenght is more than 1, throw a warning that only the first one is selected
        } else {
            this.handleError('readSingleton', 'tablename not provided', true)
        }
    }

    /**
     * Takes an array of string values, matches them against a property field
     * in each record, and deletes them.
     * @param {*} tableName name of table to do batch delete against
     * @param {*} batchOfString the array of strings that have the value to match against the property field of each record in table
     * @param {*} predicateField the field to match against in each record. Eg: 'offline_id' will get every record that matches against a value in `batchOfString`
     * @param {*} debug default is false. when set to `true`, it will return the matched data that is primed for deletion. Great for debugging, as it won't do the actual delete
     * @returns 
     */
    async batchDelete(tableName = null, batchOfString = [], predicateField = null, debug = false) {
        if (this.checkArguments(['tableName', 'batchOfString', 'predicateField'], ['tableName', 'batchOfString', 'predicateField'], 'batchDelete')) {
            if (batchOfString.length > 0 && typeof tableName === 'string' && tableName) {
                if (debug) {
                    const batchProcess = batchOfString.map((stringValue) => {
                        const predicateCheck = {}
                        predicateCheck[predicateField] = stringValue
                        return this.db.collection(tableName).doc(predicateCheck).get()
                    })
                    const [data, error] = await this.handlePromise(Promise.all(batchProcess))
                    if (error) {
                        this.handleError('Debug batchDelete', `${error} Will do nothing`, false)
                        return []
                    } else {
                        console.log('matched records for deletion so far', data)
                        return data
                    }
                } else {
                    const batchProcess = batchOfString.map((stringValue) => {
                        const predicateCheck = {}
                        predicateCheck[predicateField] = stringValue
                        return this.db.collection(tableName).doc(predicateCheck).delete()
                    })
                    const [data, error] = await this.handlePromise(Promise.all(batchProcess))
                    if (error) {
                        this.handleError('Actual batchDelete', `error: ${error} Will do nothing`, true)
                    } else {
                        console.log('deleted records', data)
                        return data
                    }
                }
            } else {
                this.handleError('batchDelete', `missing or incorrect parameter ${JSON.stringify(arguments)}. Will do nothing`, false)
            }
        }
    }

    async getMetaData(tableName = null) {
        // debugger
        if (tableName) {
            const [data, error] = await this.handlePromise(this.readSingleton(`meta_data_${tableName}`))
            if (error) {
                this.handleError('getMetaData', `error reading: ${error}`)
            } else {
                return data
            }
        } else {
            this.handleError('getMetaData', 'tableName not supplied')
        }
    }

    async saveMetaData(tableName = null, payload = null) {
        if (tableName && payload) {
            const adjustedPayload = {...payload, modulename: tableName, businessID: this.BusinessId}
            // const [data, error] = await this.handlePromise(this.saveSingleton(`meta_data_${tableName}`, payload))
            const [data, error] = await this.handlePromise(this.db.collection(`meta_data_${tableName}`).set([adjustedPayload]))
            if (error) {
                this.handleError('saveMetaData', `error reading: ${error}`)
            } else {
                return {...data, extra: { businessID: this.BusinessId, moduleName: tableName, metaData: payload }}
            }
        } else {
            this.handleError('saveMetaData', 'missing parameter')
        }
    }

    async saveSingleton(tableName = null, payload = null) {
        if (tableName && payload) {
            console.log('saveSingleton running with param', arguments)
            const [data, error] = await this.handlePromise(this.getAll(tableName))
            if (error) {
                this.handleError('saveSingleton', `error on reading: ${error}`, true)
            } else {
                console.log('data from singleton: ', data)
                if (data.length <= 1) {
                    // check if lenght is exactly 1
                    // save data
                    const [savedData, saveError] = await this.handlePromise(this.db.collection(tableName).set([payload]))
                    if (savedData) {
                        console.log('Save attempt: ', savedData)
                        return savedData
                    } else {
                        this.handleError('saveSingleton', `error while setting: ${saveError}`)
                    }
                } else {
                    this.handleError('saveSingleton', `checking data from table '${tableName}' shows more than one result. Will not save`, false)
                    return {} // or null?
                }
            }
        } else {
            this.handleError('saveSingleton', 'missing paramter', true)
        }
    }

    handleError(functionName, errorMessage, throwError = true) {
        if (throwError) {
            throw new Error(`${functionName}: ${errorMessage}`)
        } else {
            console.error(`${functionName}: ${errorMessage}`)
            return `${functionName}: ${errorMessage}`
        }
    }

    async batchInsert(tableName = null, dataRecords = []) {
        if (this.checkArguments(['tableName'], ['tableName'], 'batchInsert')) {
            if (tableName && dataRecords && this.isArray(dataRecords) && dataRecords.length > 0) {
                const batchProcess = dataRecords.map(record => this.insert(tableName, record))
                const [data, error] = await this.handlePromise(Promise.all(batchProcess))
                if (error) {
                    this.handleError('batchInsert', `BatchProcess Failed: ${error}`)
                } else {
                    console.log('batchInsert result: ', data)
                    return data
                }
            } else {
                this.handleError('batchInsert', `one/more missing paramters or empty array ${JSON.stringify(arguments)}. Returning []`, false)
                return []
            }
        }
    }

    async handlePromise(suppliedPromise) {
        try {
            const data = await suppliedPromise
            return [data, null]
        } catch (error) {
            return [null, error]
        }
    }

    async getAll(tableName) {
        // debugger
        const results = await this.db.collection(tableName).get() || []
        console.log('localbase results', results)
        // return results.filter(record => !hasMetaRecord(record, tableName) && !hasSyncStatus(record, tableName))
        return falseDestinationRemoval(results, tableName)
    }

    async getSyncedAndUnSyncedRecords(tableName) {
        const synced = await this.db.collection(tableName).get()
        const unsynced = await this.db.collection(`${tableName}_unsynced`).get()
        console.log('synced/unsynced', [synced, unsynced])
        const combined = synced.concat(unsynced)
        console.log('getSyncedAndUnSyncedRecords results:', combined)
        return synced
    }

    async updateRecord(tableName = null, filterCriteria = { propName: null, propValue: null }, updatePayload = null) {
        if (tableName && filterCriteria.propName && filterCriteria.propValue && updatePayload) {
            // do update
            const filter = {}
            filter[filterCriteria.propName] = filterCriteria.propValue
            const updateProcess = await this.db.collection(tableName).doc(filter).update(updatePayload)
            return updateProcess
        } else {
            this.handleError('updateRecord', `parameter not valid or provided. Arguments: ${JSON.stringify(arguments)}`)
        }
    }

    async searchAccrossRecordFields(tableName = null, searchTerm = null, fieldNames = []) {
        if (tableName && fieldNames.length > 0) {
            const results = await this.db.collection(tableName).get()
            const results_unsynced = await this.db.collection(`${tableName}_unsynced`).get()
            // debugger
            const combined_results = results.concat(results_unsynced)
            console.log('localbase results', results)
            const filtered = combined_results.filter((record) => {
                let combinedString = ''
                let checksOut = false
                fieldNames.forEach((fieldName) => {
                    combinedString += `${record[fieldName]} `
                })
                const searchIndex = (combinedString || '').toLowerCase().search(searchTerm.toLowerCase())
                if (searchIndex > -1) {
                    checksOut = true
                }
                // debugger
                return checksOut
                // let checksOut = false
                // fieldNames.forEach((fieldName) => {
                //     const recordValue = record[fieldName]
                //     const searchIndex = (recordValue || '').search(searchTerm)
                //     if (searchIndex > -1) {
                //         checksOut = true
                //     }
                //     // debugger
                // })
                // return checksOut
            })
            console.log(`search across fields ${JSON.stringify(fieldNames)} using ${searchTerm}:`, filtered)
            return filtered
        } else {
            this.handleError('searchAccrossRecordFields', `missing/incorrect parameter: ${JSON.stringify(arguments)}`)
        }
    }

    async search(tableName = null, options = { predicate: null }) {
        const filterPredicate = options.predicate || [{
            value: 'Hello',
            field: 'value',
            operator: 'LIKE'
        }]
        const result = await this.getAll(tableName)
        return this.doSearchFiltering(result, filterPredicate)
    }

    doSearchFiltering(dataArray, filterPredicate) {
        console.log('doSearchFiltering: ', arguments)
        let result = []
        result = dataArray.filter(item => this.verifyPredicateArray(item, filterPredicate))
        return result
    }

    isArray(someArray) {
        return typeof someArray === 'object' && Array.isArray(someArray)
    }

    verifyPredicate(item, predicate = {
        value: '',
        field: '',
        operator: ''
    }) {
        console.log('verifyPredicate:', item, predicate)
        let isValid = false
        switch (predicate.operator) {
            case 'LIKE': {
                const searchIndex = (item[predicate.field] || '').toLowerCase().search(predicate.value.toLowerCase())
                console.log(`using .search( ${(item[predicate.field] || '').toLowerCase()}, ${predicate.value.toLowerCase()}): ${searchIndex}`)
                isValid = searchIndex > -1
                break;
            }

            case 'EQ': {
                isValid = (item[predicate.field] || '').toString().toLowerCase() === predicate.value.toString().toLowerCase()
                break;
            }

            default:
                console.warn('predicate operator not recognized. Will return false by default')
                isValid = false
                break;
        }
        return isValid
        // console.log(`verifyPredicate: ${field}, ${value}`)
        // if (field && value) {
        //     const searchIndex = field.toLowerCase().search(value.toLowerCase())
        //     console.log(`using .search( ${field}, ${value}): ${searchIndex}`)
        //     return searchIndex > -1
        // } else {
        //     throw new Error(`verifyPredicate missing param: ${JSON.stringify(arguments)}`)
        // }
    }

    verifyPredicateArray(item, predicateArray = []) {
        // TODO. The case switch for each operator in predicateArray[] should be done here
        // or abstracted to its own method
        let allRulesPass = false
        allRulesPass = predicateArray.every(predicate => this.verifyPredicate(item, predicate))
        return allRulesPass
    }

    insert(tableName, valueObject = {}) {
        return this.db.collection(tableName).add(valueObject)
    }
}