<template>
  <div
    class="
      bg-white
      rounded-lg
      big-shadow
      h-48
      mx-auto
      text-center
      flex
      items-center
      content-center
      px-4
      max-w-lg
      my-2
    "
  >
    <div class="flex-1" v-if="syncStatus === 'syncing'">
      <div v-if="isMsgOnline && !progress.error">
        <h4 class="title">Syncing is ongoing</h4>
        <p class="paragraph-style">We'll let you know when its done</p>
        <!-- Progress Bar -->
        <div
          :class="{ 'animate-pulse': progress.total <= 0 }"
          class="h-4 bg-gray-300 w-1/3 rounded mx-auto my-3 relative"
        >
          <div
            :style="{ width: percentageProgress }"
            class="bg-blue-300 absolute top-0 left-0 bottom-0"
          ></div>
        </div>

        <p v-if="progress.type === 'fetch' && !progress.error" class="paragraph-style">
          Fetching data from Traction Servers for
          <span class="font-semibold">'{{ progress.name }}'</span> :
          {{ convertToMegabytes(progress.done) }}
        </p>
        <p v-if="progress.type === 'offline' && !progress.error" class="paragraph-style">
          Saving records for offline access:
          <span class="font-semibold">'{{ progress.name }}'</span> :
          {{ percentageProgress }}
          <span class="block my-1 font-semibold text-red-500"
            >Do not close your browser</span
          >
        </p>
        <!-- End Progress Bar -->
      </div>

      <!-- Error Message -->
      <div v-if="progress.error" class="paragraph-style text-center">
        <h4 class="title text-red-700">A sync error occured</h4>
        <span class="block">{{progress.error}}</span>
        <button class="px-3 mt-2 mb-4 py-2 rounded-sm bg-red-500 text-white" @click="initiateReSync()">
          Try again
        </button>
      </div>
      <!-- End Error Message -->
      
      <!-- <div v-else>
        <h4 class="title">Getting your offline records</h4>
        <p class="paragraph-style">
          Your data is loaded from offline. This will disappear when its done
        </p>
      </div> -->
    </div>
    <div class="flex-1" v-if="syncStatus === 'done'">
      <p class="paragraph-style">Syncing is done</p>
    </div>
    <div class="flex-1" v-if="syncStatus === 'failed'">
      <h4 class="title">Syncing failed</h4>
      <p class="paragraph-style">You can try again</p>
    </div>
    <!-- <div v-else>
        Default message
    </div> -->
  </div>
</template>

<script>
import Button from "../../components/components/Button.vue";
import appState from "../offline.store";
export default {
  components: { Button },
  data() {
    return {
      syncStatus: this.status || false,
      isMsgOnline: appState.online,
      percentageProgress: "0%",
    };
  },
  watch: {
    progress(newValue, oldValue) {
      console.log("progress was updated:", this.progress);
      this.percentageProgress = this.percentageDone();
    },
  },
  methods: {
    convertToMegabytes(done = 0) {
        const megaBytes = done / 1000000
        return `${megaBytes.toFixed(2)} MB`
    },
    percentageDone() {
      let percentage = 0;
      if (this.progress.done && this.progress.total) {
        if (this.progress.done >= this.progress.total) {
          percentage = 100;
        } else {
          percentage = Math.floor(
            (this.progress.done / this.progress.total) * 100
          );
        }
      }
      //   debugger
      return percentage + "%";
    },
    initiateReSync() {
      console.log('button clicked. emitting resync')
      this.$emit('onResync', true)
    }
  },
  //   computed: {
  //     percentageDone() {
  //       let percentage = 0;
  //       if (this.recordsDone && this.recordsTotal) {
  //         if (this.recordsDone >= this.recordsTotal) {
  //           percentage = 100;
  //         } else {
  //           percentage = Math.floor((this.recordsDone / this.recordsTotal) * 100);
  //         }
  //       }
  //       return percentage + "%";
  //     },
  //   },
  props: {
    status: {
      type: String,
      required: true,
      default: "unsupplied",
    },
    progress: {
      type: Object,
      required: false,
      default: function () {
        return {
          name: "",
          done: "",
          total: "",
          type: "",
          error: '',
        };
      },
    },
    // done: {
    //   type: Number,
    //   required: false,
    //   default: 0,
    // },
    // total: {
    //   type: Number,
    //   required: false,
    //   default: 0,
    // },
  },
};
</script>

<style lang="postcss" scoped>
.title {
  @apply font-bold mt-4 text-2xl whitespace-no-wrap mb-2;
}
.paragraph-style {
  @apply text-xs;
}
</style>