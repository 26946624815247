import { ADD_DATA_TO_DB, CONNECT_TO_DB, GET_DB_DATA, GET_OBJECT_STORE, SAVE_OBJECT_STORE } from '../../browser-db-config/indexedDB';
import { GET_USER_BUSINESS_ID } from '../../browser-db-config/localStorage';
import { HTTP_REQUEST } from '../../http-config/trac-http-req';

const state = {
	allSalesHistory: null,
	salesStatsOverTime: null,
};

const getters = {
	GET_ALL_SALES_HISTORY: (state) => state.allSalesHistory,
	GET_SALES_STATS: (state) => state.salesStatsOverTime,
};

const actions = {
	FETCH_OFFLINE_SALES: async function (context, payload) {
		let offlineSalesHistory = await GET_OBJECT_STORE('offline_db_sales_history', 'sales_history');
		context.commit('SET_UP_SALES_HISTORY', offlineSalesHistory);
		return offlineSalesHistory;
	},
	FETCH_SALES_HISTORY_V2: async function (context, payload) {
		const lastUpdateQuery = payload.lastUpdate ? `?updated_at=${payload.lastUpdate}` : ''
		const httpReq = {
			url: `/v1/sales/businesses/${GET_USER_BUSINESS_ID()}/${payload._id || 'all'}${lastUpdateQuery}`,
			method: 'GET',
			authRequest: true,
		};
		return HTTP_REQUEST(httpReq)
	},
	FETCH_SALES_HISTORY: async function (context, payload) {
		// Open and get offline sales history
		// const offlineSalesHistoryDB = await CONNECT_TO_DB('offline_db_sales_history', 1, 'sales_history');
		// let offlineSalesHistory = await GET_DB_DATA(offlineSalesHistoryDB, 'sales_history');
		let offlineSalesHistory = await GET_OBJECT_STORE('offline_db_sales_history', 'sales_history');


		// const notSyncedOfflineSalesDB = await CONNECT_TO_DB('not_synced_offline_sales', 1, 'not_synced_sales');
		// let notSyncedOfflineSales = await GET_DB_DATA(notSyncedOfflineSalesDB, 'not_synced_sales');
		let notSyncedOfflineSales = await GET_OBJECT_STORE('not_synced_offline_sales', 'not_synced_sales');
		let syncedOfflineSales = await GET_OBJECT_STORE('offline_db_sales_history_not_merged', 'sales_history') || [];

		// Filter the offline synced sales
		if (offlineSalesHistory) {
			offlineSalesHistory.data.salesHistory = (offlineSalesHistory.data.salesHistory || []).filter(sale => sale.receipt_number && sale.transaction_type);
			syncedOfflineSales = syncedOfflineSales.filter(sale => sale.timestamp > offlineSalesHistory.data.metaData.lastUpdate);
		}


		// Clear already synced sale *****??
		const filteredNotSyncedSales = (notSyncedOfflineSales || []).filter(sale => sale.is_offline_data);

		// await ADD_DATA_TO_DB(notSyncedOfflineSalesDB, 'not_synced_sales', filteredNotSyncedSales);
		await SAVE_OBJECT_STORE('not_synced_offline_sales', 'not_synced_sales', filteredNotSyncedSales);

		// Save filtered list
		await SAVE_OBJECT_STORE('offline_db_sales_history_not_merged', 'sales_history', syncedOfflineSales);

		// if (!offlineSalesHistory) {
		// 	// So Request url condition doesn't break
		// 	offlineSalesHistory = {
		// 		data: {},
		// 		status: true
		// 	};
		// }

		let filteredSyncedSales = syncedOfflineSales.map(sale => sale.sale);
		filteredSyncedSales = [].concat.apply([], filteredSyncedSales);

		const httpReq = {
			url: `/v1/sales/businesses/${GET_USER_BUSINESS_ID()}/${payload._id || 'all'}${((offlineSalesHistory || {}).data || {}).metaData
				? "?updated_at=" + (((offlineSalesHistory || {}).data || {}).metaData || {}).lastUpdate
				: ""
				}`,
			method: 'GET',
			authRequest: true,
		};

		// Fetch Online
		HTTP_REQUEST(httpReq).then(httpRes => {
			// console.log(httpRes);
			// if (httpRes.data) {
			// 	// Save assets to indexDB
			// 	// await ADD_DATA_TO_DB(offlineSalesHistoryDB, 'sales_history', httpRes);
			// }

			if (((offlineSalesHistory || {}).data || {}).salesHistory) {
				if (httpRes.data) {
					// Update the list
					offlineSalesHistory.data.salesHistory.unshift(...httpRes.data.salesHistory);
					// Update the metadata
					offlineSalesHistory.data.metaData = httpRes.data.metaData || offlineSalesHistory.data.metaData;
					syncedOfflineSales = syncedOfflineSales.filter(sale => sale.timestamp > offlineSalesHistory.data.metaData.lastUpdate);

					filteredSyncedSales = syncedOfflineSales.map(sale => sale.sale);
					filteredSyncedSales = [].concat.apply([], filteredSyncedSales);


					SAVE_OBJECT_STORE('offline_db_sales_history', 'sales_history', offlineSalesHistory);
					SAVE_OBJECT_STORE('offline_db_sales_history_not_merged', 'sales_history', syncedOfflineSales);
				}
			} else {
				if (!offlineSalesHistory) {
					offlineSalesHistory = {}
				}
				offlineSalesHistory.data = httpRes.data || {};
			}

			offlineSalesHistory.status = httpRes.status;

			if (!offlineSalesHistory.data) {
				offlineSalesHistory.data = {}
			}

			// offlineSalesHistory.data.salesHistory = [...filteredSyncedSales, ...((filteredNotSyncedSales || []).map(sale => {
			// 	return { ...sale, isNotSyncedSale1: true };
			// })), ...(offlineSalesHistory.data.salesHistory || [])];

			context.commit('SET_UP_SALES_HISTORY', offlineSalesHistory);
		});

		// Offline operations
		// if (offlineSalesHistory.data.salesHistory) {
		// 	if (httpRes.data) {
		// 		// Update the list
		// 		offlineSalesHistory.data.salesHistory.unshift(...httpRes.data.salesHistory);
		// 		// Update the metadata
		// 		offlineSalesHistory.data.metaData = httpRes.data.metaData || offlineSalesHistory.data.metaData;
		// 	}
		// } else {
		// 	offlineSalesHistory.data = httpRes.data || {};
		// }

		// if (!offlineSalesHistory.data) {
		// 	offlineSalesHistory.data = {}
		// }

		// console.log(filteredNotSyncedSales);
		// console.log(offlineSalesHistory);

		// ...((filteredNotSyncedSales || []).map(sale => {
		// 	return { ...sale, isNotSyncedSale1: true };
		// })),

		if (!offlineSalesHistory) {
			offlineSalesHistory = await HTTP_REQUEST(httpReq);
		}

		// if (offlineSalesHistory.status) {
		// 	(offlineSalesHistory.data || {}).salesHistory = [...filteredSyncedSales, ...((filteredNotSyncedSales || []).map(sale => {
		// 		return { ...sale, isNotSyncedSale1: true };
		// 	})), ...((offlineSalesHistory.data || {}).salesHistory || [])];
		// }

		// console.log(offlineSalesHistory);


		context.commit('SET_UP_SALES_HISTORY', offlineSalesHistory);

		return offlineSalesHistory;
	},
	// UPDATE_SALE_CUSTOMER: async function (context, payload) {
	// 	const httpReq = {
	// 		url: `/v1/sales/${id}`,
	// 		method: 'PATCH',
	// 		authRequest: true,
	// 		body: payload,
	// 	};

	// 	const httpRes = await HTTP_REQUEST(httpReq);
	// },
	FETCH_SALE: async function (context, payload) {
		const httpReq = {
			url: `/v1/sales/${payload.saleId}/businesses/${GET_USER_BUSINESS_ID()}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
	},
	FETCH_SALES_STATS_OVER_TIME_PERIOD: async function (context, payload) {
		const httpReq = {
			url: `/v1/dashboard/sales/revenue/${GET_USER_BUSINESS_ID()}/${payload.startDate
				}/${payload.endDate}/${payload.storeID}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		context.commit('SET_UP_SALES_HISTORY_STATS_OVER_TIME', httpRes);
	},
	DOWNLOAD_SPREADSHEET: async function (context, url) {
		const httpReq = {
			url: url,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	GET_CUSTOMER_JSON_PAYMENT: async function (context, payload) {
		const httpReq = {
			url: `/v1/customers/reports/salesbycustomer/business/${GET_USER_BUSINESS_ID()}?startdate=${payload.startDate
				}&enddate=${payload.endDate}`,
			method: 'POST',
			authRequest: true,
			body: payload.storeIds,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	GET_SALES_BY_PRODUCT_JSON_PAYMENT: async function (context, payload) {
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/reports/getsalesbyproduct?startdate=${payload.startDate
				}&enddate=${payload.endDate}`,
			method: 'POST',
			authRequest: true,
			body: payload.storeIds,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	FETCH_EMPLOYEE_SALES_DATA: async function (context, payload) {
		const httpReq = {
			url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/reports/salesbyemployee?employeeid=${payload.employeeId}`,
			// url: `/v1/businesses/${GET_USER_BUSINESS_ID()}/reports/salesbyemployee?startdate=${payload.startdate}&enddate=${payload.endDate}&employeeid=${payload.employeeId}`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
	FETCH_EMPLOYEE_SALES_SUMMARY: async function (context, payload) {
		const httpReq = {
			url: `/v1/sales/employee/${payload.employeeId}/summary`,
			method: 'GET',
			authRequest: true,
		};

		const httpRes = await HTTP_REQUEST(httpReq);
		return httpRes;
	},
};

const mutations = {
	SET_UP_SALES_HISTORY: (state, data) => {
		// sessionStorage.setItem('SET_UP_SALES_HISTORY', JSON.stringify(data));
		state.allSalesHistory = data;
	},
	SET_UP_SALES_HISTORY_STATS_OVER_TIME: (state, data) => {
		state.salesStatsOverTime = data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
