<template>
  <main
    class="
      z-50
      fixed
      shadow-sm
      w-full
      top-0
      h-16
      px-2
      md:pr-10
      md:pl-10 md:pr4
      bg-white
      items-center
      flex
      non-print-slip
    "
  >
    <div class="flex h-8 items-center">
      <!-- <div class="w-5 mr-3 flex items-center">
        <svg
          @click="toggle"
          class="w-5 h-5 cursor-pointer absolute md:hidden block mx-1"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.425293" width="15" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="7.92529" width="21" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="15.4253" width="25" height="2.5" rx="1.25" fill="#253B95" />
        </svg>
      </div> -->

      <svg @click="toggle()" v-if="active" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 toggle-click h-5 cursor-pointer"><path d="M21.2 2L2 21.2" stroke="#979797" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 2L21.2 21.2" stroke="#979797" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      <svg @click="toggle()" v-else viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 toggle-click cursor-pointer"><rect y="0.425293" width="15" height="2.5" rx="1.25" fill="#253B95"></rect><rect y="7.92529" width="21" height="2.5" rx="1.25" fill="#253B95"></rect><rect y="15.4253" width="25" height="2.5" rx="1.25" fill="#253B95"></rect></svg>

      <!-- <div class="w-5 mr-3 flex items-center" id="sidebar-switch">
        <svg
          v-if="!active"
          @click="toggle"
          class="w-5 h-5 cursor-pointer absolute md:hidden block mx-1"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="0.425293" width="15" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="7.92529" width="21" height="2.5" rx="1.25" fill="#253B95" />
          <rect y="15.4253" width="25" height="2.5" rx="1.25" fill="#253B95" />
        </svg>
        <svg
          v-else
          @click="toggle"
          class="w-4 h-4 cursor-pointer absolute"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.2 2L2 21.2"
            stroke="#979797"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 2L21.2 21.2"
            stroke="#979797"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div> -->

      <!-- 
      <svg
        class="block mx-1 cursor-pointer md:hidden"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#003283"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg> -->

      <!-- <div @click="$router.push('/')" class="flex items-center cursor-pointer">
        <img
          src="@/assets/svg/otherLogo.svg"
          alt="Traction Logo"
          class="block ml-2"
        />
        <h2 class="text-xs pl-2 pt-2 font-medium text-primaryBlue">
          traction apps
        </h2>
      </div> -->

      <div id="click-go-home" @click="gotoHome()" class="flex items-center cursor-pointer">
        <img
          src="@/assets/images/traction-logo.png"
          alt="Traction Logo"
          class="hidden sm:block w-24 ml-6"
        />
        <img class="sm:hidden w-4 h-4 ml-3" src="@/assets/images/logo.png" alt="">
        <!-- <h2 class="text-xs pl-2 pt-2 font-medium text-primaryBlue">
          traction apps
        </h2> -->
      </div>

    </div>
    <div class="flex-1"></div>
    <div class="flex items-center">
      <div class="text-xs mr-6"></div>
      <div
        class="
          smallest
          hidden
          h-8
          w-8
          sm:flex
          justify-center
          items-center
          rounded-full
          mr-4
          bg-accentLight
        "
      >
        <div class="tiny text-accentDark cursor-pointer uppercase">
          {{ nameInitials }}
        </div>
      </div>
      <div class="text-xs md:mr-5">
        <select name="" id="" class="p-3" @change="selectUser">
          <option value="">Add Another User</option>
          <option
            class="capitalize"
            :selected="authUser.user._id === user._id"
            v-for="authUser in authUsers"
            :key="authUser.user._id"
            :value="authUser.user._id"
          >
            {{ authUser.user.name }}
          </option>
        </select>
      </div>
      <div id="log-user-out" @click="logUserOut()" class="mx-3 block cursor-pointer sm:hidden">
        <img src="@/assets/logout.svg" alt="">
      </div>
      <div
        class="mx-4 hidden md:block md:mx-4"
        v-click-outside="hideMoreOptions"
        tabindex="0"
      >
        <img
          @click="showingMoreOptions = !showingMoreOptions"
          src="./../../assets/svg/more-horizontal.svg"
          alt=""
          class="cursor-pointer"
        />
        <div
          v-if="showingMoreOptions"
          class="
            p-2
            shadow
            rounded
            absolute
            bg-white
            right-0
            mr-4
           drop-down-options
          "
          style="top: 90%"
        >
          <div class="flex items-center drop-down-options">
            <div
              class="
                smallest
                h-8
                w-8
                flex
                justify-center
                items-center
                rounded-full
                mr-4
                bg-accentLight
              "
            >
              <div class="tiny text-accentDark cursor-pointer uppercase">
                {{ nameInitials }}
              </div>
            </div>
            <p class="text-xs" v-if="user">
              {{ user.name }}
            </p>
          </div>
          <hr class="my-3 drop-down-options" />
          <router-link
            :to="{ name: 'CompanyInfo' }"
            class="text-sm block py-1 cursor-pointer mb-2 pl-2 pr-6 drop-down-options"
            >My account</router-link
          >
          <!-- <router-link
            :to="{ name: 'UserRoles' }"
            class="text-sm block py-1 cursor-pointer mb-2 pl-2 pr-6 drop-down-options"
            >Settings</router-link
          > -->
          <button
            @click="logUserOut"
            class="
              bg-none
              border-none
              outline-none
              text-red-600 text-sm
              py-1
              cursor-pointer
              mb-2
              pl-2
              pr-6
              w-full
              flex
              justify-start
              drop-down-options
            "
          >
            Logout
          </button>
        </div>
      </div>
    </div>
    <div class="dropdown inline-block relative">
        <button class="font-semibold py-2 px-0 rounded inline-flex items-center">
          <!-- <span class="mr-1">Dropdown</span> -->
          <img class="px-2 h-8 animate-spin" src="../../assets/svg/syncing.svg" v-if="anyOngoing" alt="Syncing" />
          <img class="px-2 h-8" src="../../assets/svg/syncidle.svg" v-else alt="Idle" />
          
          <span class="mr-1 text-xs font-normal" v-if="anyOngoing">
            Syncing
          </span>
          <span class="hidden sm:inline-block mr-1 text-xs font-normal" v-else>
            Synced
          </span>
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
        </button>
        <ul class="dropdown-menu bg-white absolute hidden box_style right-0 w-65 text-gray-700 pt-1">
          <div class="px-3 py-6" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <!-- <h1 class="text-xl">Sync Settings</h1> -->
            <div v-if="anyOngoing">
              <div v-for="(moduleItem, index) in syncManifestList" :key="index" class="m-2">
                <div v-if="moduleItem">
                  <h3>
                    <span class="text-base font-semibold capitalize mr-2">{{ moduleItem.name }}</span>
                    <span
                      v-if="moduleItem.type === 'fetch'"
                      class="text-xs italic opacity-75"
                    >
                      Fetching from traction servers -
                      {{ convertToMegabytes(moduleItem.done) }}
                    </span>
                    <span
                      v-if="moduleItem.type === 'offline'"
                      class="text-xs italic opacity-75"
                    >
                      Saving to offline records
                      {{
                        calculateProgressPercentage(
                          moduleItem.done,
                          moduleItem.total
                        )
                      }}
                    </span>
                  </h3>
                  <progress-bar-component class="h-4 w-full my-2"
                    :done="moduleItem.done"
                    :total="moduleItem.total"
                  />
                </div>
              </div>
            </div>

            <div class="text-xs" v-if="anyOngoing === false">
              <div>No ongoing sync operation</div>
            </div>

            <router-link to="/syncsettings">
              <button class="px-4 py-3 mt-3 text-xs rounded-md text-white button-style font-semibold">Sync Settings</button>
            </router-link>
          </div>
        </ul>
    </div>
    <div v-if="anyOngoing" class="h-1 animated-gradient absolute w-full left-0 right-0 bottom-0"></div>
  </main>
</template>

<script>
import {
  CLEAR_LIST,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";
// import { FETCH_ALL_DATA } from "../../browser-db-config/utilityMethods";
import {
  DELETE_AUTH_USER,
  GET_CURRENT_AUTH_USER,
  GET_USER_DATA,
  GET_USER_TOKEN,
  ITERATEALL_ALL_AUTH_USERS,
  SAVE_LOCAL_DB_DATA,
} from "./../../browser-db-config/localStorage";
import { eventBus } from "../../../src/eventBus";
import ClickOutside from 'vue-click-outside'
import ScreenMixin from '../../offline-module/mixins/Screensize';
import SyncMonitorMixin from "../../offline-module/mixins/syncMonitoring";
export default {
  mixins: [SyncMonitorMixin, ScreenMixin],
  data() {
    return {
      user: GET_USER_DATA(),
      active: true,
      showingMoreOptions: false,
      authUsers: null,
    };
  },
  watch: {
    $route(x) {
      if (x) {
        if (screen.width < 640 && this.active) {
          this.active = !this.active;
        }
      }
    },
  },
  created() {
    this.active = this.collapseByDefault() ? false : true
    this.authUsers = ITERATEALL_ALL_AUTH_USERS();
    this.fetchLoyaltyPointToken();
  },
  computed: {
    nameInitials() {
      if (!this.user) {
        return;
      }
      if (this.user.name) {
        return this.user.name
          .split(" ")
          .map((name) => name.substring(0, 1))
          .join("");
      }
    },
  },
  directives: {
    ClickOutside
  },
  methods: {
    gotoHome() {
      this.$router.push('/').catch((err) => {
        // catch the error but show nothing
      })
    },
    hideMoreOptions(e) {
      if (!e.target.classList.contains("drop-down-options")) {
        setTimeout(() => {
          this.showingMoreOptions = false;
        }, 100);
      }
    },
    toggle() {
      this.active = !this.active;
      this.$emit("toggle", { active: this.active });
    },
    selectUser(event) {
      const selectedUserID = event.target.value;

      if (selectedUserID === "") {
        this.$router.replace({ name: "Login" });
        return;
      }

      const selectedUser = GET_CURRENT_AUTH_USER(selectedUserID);

      if (!selectedUser.tokenTimeStamp) {
        eventBus.$emit("trac-alert", {
          message:
            "Logged you out because of an update. Kindly log back in and you'd be fine",
        });

        this.logUserOut();
        return;
      }

      SAVE_LOCAL_DB_DATA("traction-app-user-data", selectedUser.userData);
      SAVE_LOCAL_DB_DATA(
        "traction-app-current-user-business-data",
        selectedUser.businessData
      );
      SAVE_LOCAL_DB_DATA("auth-token-date", selectedUser.tokenTimeStamp);
      SAVE_LOCAL_DB_DATA(
        "secondary-auth-token",
        selectedUser.secondaryTokenData
      );

      this.user = GET_USER_DATA();
      // Inital logic
      window.location.reload();
    },
    confirmAsAPromise(msg) {
      return new Promise((resolve, reject)=>{
        const confirmed = window.confirm(msg)
        return confirmed ? resolve(true) : reject(false)
      })
    },
    logUserOut() {
      CLEAR_LIST();
      this.confirmAsAPromise('Do you want to log out?').then(() => {
         this.$store.dispatch('LOGOUT', { user_id: this.user._id }).then((res) => {
          if(res.status) {
            console.log('Logged Out', this.user._id)
            CLEAR_LIST();
            // localStorage.clear();
            DELETE_AUTH_USER();
            sessionStorage.clear();
            this.$intercom.shutdown(); // Shutdown intercom
            this.$MixpanelReset(); // Reset Mixpanel
            this.clearAllPeriodicSyncInstructions()
            this.$router.replace('/login');
          }
          else {
            this.$store.commit('TOGGLE_LOADING_FOR_LOGOUT', false)
          }
        }).catch(()=>{})
      })
      
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          await this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          // FETCH_ALL_DATA();
          await SAVE_OBJECT_STORE(
            "secondary_token_db",
            "secondary_token",
            data.data
          );

          // Check is store credit for buiness is active
          this.$store.dispatch("CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE");
        })
        .catch((error) => {
          // console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.tiny {
  font-size: 0.7rem;
}
.dropdown:hover .dropdown-menu {
  display: block;
}

.box_style {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
}

.button-style {
  background: linear-gradient(318.39deg, #0143AA 8.55%, #003283 90.68%);
}


/* Animated Gradient background */
.animated-gradient {
    background: linear-gradient(272deg, #253B95, #8297e1);
    background-size: 400% 400%;

    -webkit-animation: gradient-animation 1s ease infinite;
    -moz-animation: gradient-animation 1s ease infinite;
    animation: gradient-animation 1s ease infinite;
}

@-webkit-keyframes gradient-animation {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
}
@-moz-keyframes gradient-animation {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
}
@keyframes gradient-animation {
    0%{background-position:0% 47%}
    50%{background-position:100% 54%}
    100%{background-position:0% 47%}
}

@media print {
  .non-print-slip {
    display: none;
  }
}
</style>