<template>
  <div
    class="inline-flex items-center justify-between relative w-44"
    v-click-outside="close"
  >
    <button
      @click="active = !active"
      id="options-menu"
      :disabled="disabled"
      aria-haspopup="true"
      aria-expanded="true"
      type="button"
      :class="extraClass"
      class="border smallest text-primaryBlue focus:outline-none border-primaryBlue leading-4 px-4 rounded inline-flex justify-between capitalize items-center min-w-full w-60"
    >
      {{ newTitle }}
      <svg
        class="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </button>
    <ul
      v-if="active"
      class="w-full max-height-two h-auto overflow-y-auto absolute capitalize z-40 top-0 mt-12 min-width rounded border white-gradient cursor-pointer text-primaryBlue pt-1"
    >
      <li
        @click="newModuleOnTheFly"
        v-if="allowNewModule"
        class="bg-accentLight text-xs py-2 px-4 block whitespace-no-wrap"
      >
        {{ newModule }}
      </li>
      <div v-if="checkBoxItems" class="">
        <li
          class="bg-white py-2 px-2"
          v-for="(menuItem, index) in menuItems"
          :key="index"
        >
          <trac-checkbox
            @check="addToFilter"
            :isChecked="
              checkedFilterItems.includes(menuItem._id || menuItem.name) ||
                checkedFilterItems.includes('all')
            "
            :value="menuItem"
          >
            <p class="text-xs px-2 text-primaryBlue block whitespace-no-wrap">
              {{ menuItem.name }}
            </p>
          </trac-checkbox>
        </li>
      </div>
      <div class="" v-else>
        <li
          :class="
            newTitle == menuItem.name
              ? ' bg-primaryBlue text-white rounded-none hover:bg-primaryBlue px-4 py-2 whitespace-no-wrap text-xs'
              : 'hover:bg-accentLight bg-white text-xs py-2 px-4 block whitespace-no-wrap'
          "
          @click="setValue(menuItem)"
          v-for="(menuItem, index) in menuItems"
          :key="index"
        >
          {{ menuItem.name }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "Dropdown",
  props: {
    menuItems: {
      type: Array,
    },
    title: {
      type: String,
      default: "Add Filter",
    },
    newModule: {
      type: String,
    },
    newModuleOnTheFly: {
      type: Function,
    },
    selectedData: {
      type: Array,
    },
    allowNewModule: {
      type: Boolean,
      default: false,
    },
    checkBoxItems: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, String],
    },
    choice: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    extraClass: {
      type: String,
      default: 'py-2',
    },
  },
  data() {
    return {
      active: false,
      newTitle: this.title,
      checkItems: [],
    };
  },
  directives: {
    ClickOutside,
  },
  mounted() {
    this.checkIfChecked();
  },
  computed: {
    checkedFilterItems() {
      return this.selectedData;
    },
  },
  methods: {
    checkIfChecked() {
      this.checkItems = (this.checkedFilterItems || []).map((item) => item);
    },
    addToFilter(e) {
      this.$emit("add-to-filter", e);
    },
    setValue(menuItem) {
      this.newTitle = menuItem.name;
      this.$emit("onselect", menuItem);
      if (this.choice) {
        this.$emit("input", menuItem);
      } else {
        this.$emit("input", menuItem.name || menuItem);
      }
      this.active = false;
    },
    openNewModal() {
      this.$router.push({ name: this.newModuleRoute });
    },
    close() {
      this.active = false;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        if (newValue) {
          this.newTitle = newValue.name || newValue;
        }
      },
    },
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}

.max-height-two {
  max-height: 15rem;
}
</style>