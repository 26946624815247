/** Removes False destination writes by targeting errant records from module tables */
export const falseDestinationRemoval = (
  arrayOfRecords = [],
  moduleName = '',
  targetTables = [
    'customers',
    'pos',
    'payments',
    'categories',
    'products',
    'stores',
  ]
) => {
  console.log('checking false destination with array: ', arrayOfRecords);
  const offendingIndexes = [];
  const isCandidateForChecking = targetTables.includes(moduleName);

  if (isCandidateForChecking) {
    // find any records that have data we do not want
    for (let i = 0; i < arrayOfRecords.length; i++) {
      const hasSyncStatus = (arrayOfRecords[i] || {}).hasOwnProperty('syncStatus')
      const hasMetaRecord =
        (arrayOfRecords[i] || {}).hasOwnProperty('Request') &&
        (arrayOfRecords[i] || {}).hasOwnProperty('lastUpdate');
      if (hasSyncStatus || hasMetaRecord) {
        console.log('found offender?', hasSyncStatus);
        offendingIndexes.push(i);
      }
      // debugger
    }

    for (let i = offendingIndexes.length - 1; i >= 0; i--) {
      arrayOfRecords.splice(offendingIndexes[i], 1);
    }
    console.log('offending indexes: ', offendingIndexes);
  } else {
    console.log('targetTable not valid. return normal array');
  }
  // debugger
  return arrayOfRecords;
};

export const hasSyncStatus = (data, where = '') => {
  if (data) {
    const conditionFulfilled = (data || {}).hasOwnProperty('syncStatus');
    if (conditionFulfilled) {
      console.warn(`false destination detected at ${where || 'N/A'}`, data)
    }
    return conditionFulfilled
  } else {
    return false
  }
}

export const hasMetaRecord = (data, where = '') => {
  if (data) {
    const conditionFulfilled = (data || {}).hasOwnProperty('Request') && (data || {}).hasOwnProperty('lastUpdate')
    if (conditionFulfilled) {
      console.warn(`false destination detected at ${where || 'N/A'}`, data)
    }
    return conditionFulfilled
  } else {
    return false
  }
}