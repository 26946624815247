import { GET_LOCAL_DB_DATA, GET_USER_TOKEN } from "../browser-db-config/localStorage";
import store from "./../store/index";

const checkToRefreshToken = async () => {
  let token;

  const tokenDate = new Date(GET_LOCAL_DB_DATA('auth-token-date'));
  const hours = Math.abs((new Date()).getTime() - tokenDate.getTime()) / 3600000;

  if (hours > 23 && GET_USER_TOKEN()) {
    await store.dispatch('AUTO_LOGIN');
  }
  token = GET_USER_TOKEN();

  return token;
};

export { checkToRefreshToken }; // Thuoght you might need to export this.