import mixpanel from 'mixpanel-browser';

export default {
    install(Vue, options = { isProduction: false, PROJECT_TOKEN: null, APP_VERSION: null }) {
        const debugMode = options.isProduction ? false : true
        const APP_VERSION = options.APP_VERSION
        if (options.PROJECT_TOKEN && typeof options.PROJECT_TOKEN === 'string') {
            mixpanel.init(options.PROJECT_TOKEN, {
                debug: debugMode,
                loaded: function(mixpanel) {
                    console.log('Mixpanel Loaded. Super Data: ', mixpanel.get_property('_id'))
                }
            });
            console.log('Mixpanel Initiated', options.PROJECT_TOKEN)
            // const email = mixpanel.get_property('email')
            // const id = mixpanel.get_distinct_id()
            // console.log('Mixpanel ID: ', id)
            // debugger
        } else {
            console.error('Mixpanel Setup failed:', options.PROJECT_TOKEN)
        }
        /**
         * Tracks the referrer
         */
        Vue.prototype.$TrackReferrer = function() {
            // mixpanel()
            // console.log('MixPanel Referrer: ', document.referrer)
            mixpanel.track('TA_ENTERED_SIGNUP', {
                sourceUrl: document.referrer
            })
        }
        Vue.prototype.$MixpanelGetDistinctId = function() {
            return mixpanel.get_distinct_id()
        }
        Vue.prototype.$SetAlias = function(uniqueId) {
            if(typeof uniqueId === 'string' && uniqueId.length > 0) {
                mixpanel.alias(uniqueId)
            } else {
                console.error('Set Alias Failed: ', uniqueId)
            }
        }
        Vue.prototype.$MixpanelReset = function() {
            mixpanel.reset()
        }
        Vue.prototype.$SetIdentity = function(uniqueId) {
            if(typeof uniqueId === 'string' && uniqueId.length > 0) {
                mixpanel.identify(uniqueId)
            } else {
                console.error('Set Alias Failed: ', uniqueId)
            }
        }
        Vue.prototype.$MixPanelRegister = function(paramsObject) {
            if (typeof paramsObject === 'object' && paramsObject !== null && Object.keys(paramsObject).length > 0) {
                paramsObject['APP_VERSION'] = APP_VERSION
                mixpanel.register(paramsObject, {
                    persistent: true
                })
                // console.log('Register Success: ', paramsObject)
                // console.log('Mixpanel Register Data: ', Vue.prototype.$GetPersonalAttribute('_id'))
            } else {
                console.error('Register Failed: ', paramsObject)
            }
        }
        Vue.prototype.$SetPerson = function(paramsObject, options = { set_once : false }) {
            if (typeof paramsObject === 'object' && paramsObject !== null) {
                paramsObject['APP_VERSION'] = APP_VERSION
                if (options.set_once) {
                    mixpanel.people.set_once(paramsObject)
                } else {
                    mixpanel.people.set(paramsObject)
                }
                // mixpanel.people.set(paramsObject)
            } else {
                console.error('Set Person Failed: ', paramsObject)
            }
        }
        Vue.prototype.$SetPersonalAttribute = function(paramsObject = {propName: null, propValue: null}) {
            // debugger
            if (typeof paramsObject === 'object' && paramsObject !== null && paramsObject.propName && (paramsObject.propValue || typeof paramsObject.propValue === 'boolean')) {
                mixpanel.people.set(paramsObject.propName, paramsObject.propValue)
            } else {
                console.error('Set Personal Attribute Failed: ', paramsObject)
            }
        }
        Vue.prototype.$GetPersonalAttribute = function(attribute = null) {
            if (typeof attribute === 'string' && attribute.length > 0) {
                const attributeValue = mixpanel.get_property(attribute)
                return attributeValue
            } else {
                console.error('Get Personal Attribute Failed: ', attribute)
            }
        }
        Vue.prototype.$TrackEvent = function(EventName, payload = null) {
            if(EventName && typeof EventName === 'string') {
                if (payload && typeof payload === 'object') {
                    payload['APP_VERSION'] = APP_VERSION
                    mixpanel.track(EventName, payload)
                } else {
                    mixpanel.track(EventName)
                }
            }
        }
        /**
         * Best Used when when mixpanel is initated, so we can track the load time as the page loads
         */
        Vue.prototype.$TrackLoadTime = function() {
            // mixpanel()
            console.log('Load Time: ', document.referrer)
        }
    }
}