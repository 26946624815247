<template>
    <div class="rounded-md bg-white p-12 w-3/12 text-center">
        <img class="w-12 h-12 mb-6 inline-block" src="@/assets/banned.svg" alt="">
        <div v-if="!blocked">
            <h2 class="font-semibold">Incorrect PIN</h2>
            <p class="text-xs my-5">{{ message }}</p>
            <div class="flex flex-col gap-y-2 w-3/4 justify-items-center mx-auto">
                <trac-button @button-clicked="emitForgotPassword" class="uppercase">Forgot Pin</trac-button>
                <button @click="emitCancel" class="text-xs uppercase py-2">Cancel</button>
            </div>
        </div>
        <div v-else>
            <h2 class="font-semibold">Access to Wallet Blocked</h2>
            <p class="text-xs my-5">
                Please Contact Traction
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            emitForgotPassword() {
                this.$emit('forgot-password')
            },
            emitCancel() {
                this.$emit('cancel')
            }
        },
        props: {
            message: {
                type: String,
                required: true
            },
            blocked: {
                type: Boolean,
                required: true
            }
        }
    }
</script>

<style lang="postcss" scoped>

</style>