import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  inventoryStatsOverTime: null,
};

const getters = {
  GET_INVENTORY_STATS: (state) => state.inventoryStatsOverTime,
};
const actions = {
  FETCH_INVENTORY_STATS_OVER_TIME_PERIOD: async function (context, payload) {
    const httpReq = {
      url: `/v1/dashboard/inventory/revenue/${GET_USER_BUSINESS_ID()}/${payload.startDate
        }/${payload.endDate}/${payload.storeID}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    const stateArg = "inventoryStatsOverTime";
    context.commit("SETUP_RES", { stateArg, httpRes });
  },
  FETCH_TRANSACTIONS: async function (context) {
    const httpReq = {
      url: `/v1/dashboard/gettransactionanalytics/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    // const TRANSACTIONS = await fetch(httpReq.url).then(response => response.json())
    // return TRANSACTIONS
    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes
    // const stateArg = "inventoryStatsOverTime";
    // context.commit("SETUP_RES", { stateArg, httpRes });
  },
  GETTING_STARTED: async function (context) {
    const httpReq = {
      url: `/v1/dashboard/gettingstarted/${GET_USER_BUSINESS_ID()}`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes

    // const GETTING_STARTED_CHECKS = await fetch(httpReq.url).then(response => response.json())
    // return GETTING_STARTED_CHECKS
  },
  GRAPH: async function () {
    const httpReq = {
      url: `/v1/dashboard/getgraphnalytics/${GET_USER_BUSINESS_ID()}?number_of_days=7`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    return httpRes
  },
  GRAPH_MOCK: async function () {
    return {
      "data": {
        "2022-08-31": {
          "totalValue": 3566107.06,
          "totalTransactions": 534,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-01": {
          "totalValue": 4005436.02,
          "totalTransactions": 635,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-02": {
          "totalValue": 5240787.03,
          "totalTransactions": 805,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-03": {
          "totalValue": 5778285,
          "totalTransactions": 839,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-04": {
          "totalValue": 6843470.21,
          "totalTransactions": 931,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-05": {
          "totalValue": 3607051.1,
          "totalTransactions": 573,
          "totalSales": 0,
          "totalSalesValue": 0
        },
        "2022-09-06": {
          "totalValue": 522550,
          "totalTransactions": 82,
          "totalSales": 0,
          "totalSalesValue": 0
        }
      },
      "message": "successfully fetched",
      "status": true
    }
  },
  GETTING_STARTED_MOCKED: async function (context) {
    return {
      status: true,
      message: "Getting Started information successfully fetched",
      data: {
        requestedTerminal: {
          status: true
        },
        kyc: {
          status: true
        },
        firstSale: {
          status: false
        },
        hasCreatedFixedAccount: {
          status: false
        },
        hasAddedAProduct: {
          status: true
        }
      }
    }
  },
  FETCH_TRANSACTIONS_MOCKED: async function (context) {
    return {
      data: {
        today: {
          transactionTotalToday: "50,000,000",
          totalTransactions: "83",
          mostRecentTransactions: [
            {
              value: 50000,
              label: "Cushion Cream (Mocked)"
            }
          ]
        },
        yesterday: {
          transactionTotalYesterday: "25,000,000",
          totalTransactions: 23,
          mostRecentTransactions: [
            {
              value: 10000,
              label: "Mushroom Soup"
            }
          ]
        }
      },
      error: null,
      message: "Trasnaction Data Fetched",
      status: true
    }
  },
};
const mutations = {
  SETUP_RES: (state, payload) => {
    state[payload.stateArg] = payload.httpRes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
