<template>
  <main>
    <div class="relative z-5">
      <div v-click-outside="close" @click="toggle = !toggle" class="relative">
        <div class="flex items-center relative">
          <input
            type="text"
            class="border border-primaryBlue w-full rounded-md px-5 py-4 text-xs focus:outline-none"
            :placeholder="placeholder"
            readonly
            :value="value"
          />

          <img
            class="absolute right-0 mr-5"
            src="../../assets/svg/down_arrow.svg"
            alt=""
          />
        </div>
        <p
          class="absolute top-0 -mt-2 bg-white px-2 ml-3 text-xs text-primaryBlue"
          v-if="label"
        >
          {{ labelName }}
        </p>
      </div>

      <div
        v-if="toggle"
        style="top: 4rem; max-height: 400px; overflow-y: auto"
        class="absolute z-20 w-11/12 bg-white shadow border border-gray-300 p-5"
        ref="dropdown"
      >
        <input
          @click.stop
          type="text"
          class="border px-3 py-3 mb-5 text-xs w-full rounded-md focus:outline-none"
          v-model="inputValue"
        />

        <slot :inputValue="inputValue" />
      </div>
    </div>
  </main>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    label: {
      type: Boolean,
      default: false,
    },
    labelName: {
      type: String,
      default: 'Search',
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      toggle: false,
      inputValue: '',
    }
  },
  directives: {
    ClickOutside,
  },

  watch: {
    inputValue() {
      this.$emit('input', this.inputValue)
    },
  },

  methods: {
    close() {
      this.toggle = false
      this.inputValue = ''
    },
  },
}
</script>
