const shouldBeString = (variable, label = null) => typeof variable === 'string' || `${label || variable} should be string. Provided Value: ${variable}`
const shouldBeArray = variable => Array.isArray(variable) && typeof variable === 'object' || `${variable} should be an array`
const shouldBeObject = variable => !Array.isArray(variable) && typeof variable === 'object' || `${variable} should be an array`
const shouldBeNumeric = variable => !isNaN(variable) || `${variable} should be an numeric`
const shouldBeBoolean = variable => typeof variable === 'boolean' || `${variable} should be boolean`
const shouldBeFunction = variable => typeof variable === 'function' || `${variable} should be a function`
const validateArguments = (rules = []) => {
    let valid = false
    let errors = []
    valid = rules.every(rule => rule === true)
    errors = rules.filter(rule => rule !== true)
    return [valid, errors.length > 0 ? errors : null]
}
const displayErrors = (arrayOfErrors = []) => {
    let messageString = ''
    arrayOfErrors.forEach((singleError) => {
        messageString += `${singleError} \n\r`
    })
    return messageString
}

/**
 * USAGE EXAMPLE
 * const [valid, errors] = validateArguments(
            [
                shouldBeArray(dataArray),
                shouldBeString(searchTerm),
                shouldBeArray(recordFields)
            ]
        )

        if (valid) {
            
        } else {
            throw new Error(`searchAccrossRecordFields: ` + displayErrors(errors))
        }
 */

export { shouldBeString, shouldBeFunction, shouldBeBoolean, shouldBeArray, shouldBeObject, shouldBeNumeric, validateArguments, displayErrors }