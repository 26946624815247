<template>
  <div
    :class="{ 'animate-pulse': total <= 0 }"
    class="bg-gray-300 rounded-sm relative"
  >
    <div
      :style="{ width: progress }"
      class="brand-blue absolute top-0 left-0 bottom-0"
    ></div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            progress: 0
        }
    },
    mounted () {
        this.calculateProgressPercentage()
    },
    methods: {
        calculateProgressPercentage() {
            const progress = Math.floor((this.done / this.total) * 100)
            this.progress = `${progress}%`
        }
    },
    props: {
        total: {
            type: Number,
            default: 0 
        },
        done: {
            type: Number,
            default: 0 
        },
    },
    watch: {
        total(newValue, oldValue) {
            this.calculateProgressPercentage()
        },
        done(newValue, oldValue) {
            this.calculateProgressPercentage()
        },
    },
};
</script>

<style scoped>
.brand-blue {
    background: linear-gradient(318.39deg, #0143AA 8.55%, #003283 90.68%);
}
</style>