import { GET_USER_BUSINESS_ID } from "../../browser-db-config/localStorage";
import { HTTP_REQUEST } from "../../http-config/trac-http-req";

const state = {
  allTerminals: null,
  allTerminalNames: null,
  newTerminal: null,
  singleTerminal: null,
  terminalPricing: null,
};

const getters = {
  GET_ALL_TERMINALS: (state) => state.allTerminals,
  GET_NEW_TERMINAL: (state) => state.newTerminal,
  GET_ALL_TERMINAL_NAMES: (state) => state.allTerminalNames,
  GET_SINGLE_TERMINAL: (state) => state.singleTerminal,
  GET_ALL_TERMINAL_PRICINGS: (state) => state.terminalPricing,
};

const actions = {
  FETCH_TERMINAL_REQUEST: async function(context) {
    const httpReq = {
      url: `/v1/stores/listterminalrequest/count`,
      method: "POST",
      authRequest: true,
      body: {
        businessId: GET_USER_BUSINESS_ID(),
      },
    };
    let httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_TERMINAL_PRICINGS: async function(context) {
    const httpReq = {
      url: `/v1/stores/listterminalprice`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("POPULATE_ALL_TERMINAL_PRICINGS", httpRes);
  },

  FETCH_ALL_TERMINAL_NAMES: async function(context) {
    const httpReq = {
      url: `/v1/stores/listterminalprovidernames`,
      method: "GET",
      authRequest: true,
    };

    const httpRes = await HTTP_REQUEST(httpReq);
    context.commit("POPULATE_ALL_TERMINAL_NAMES", httpRes);
  },

  // ADD_TERMINAL_TO_DB: async function(context, payload) {
  //   const httpReq = {
  //     url: `v1/stores/terminalbyserialnumber`,
  //     method: "POST",
  //     authRequest: true,
  //     body: payload,
  //   };

  //   let httpRes = await HTTP_REQUEST(httpReq);
  //   context.commit("POPULATE_ADD_TERMINAL", httpRes);
  //   return httpRes;
  // },
  ASSIGN_TERMINAL: async function(context, payload) {
    const httpReq = {
      url: `/v1/stores/terminal/assign`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    let httpRes = await HTTP_REQUEST(httpReq);
    context.commit("POPULATE_ADD_TERMINAL", httpRes);
    return httpRes;
  },

  ADD_TERMINAL_REQUEST: async function(context, payload) {
    const httpReq = {
      url: `v1/stores/addterminalrequest`,
      method: "POST",
      authRequest: true,
      body: payload,
    };

    let httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  GET_AVAILABLE_LOCATIONS: async function(context, payload) {
    const httpReq = {
      url: `v1/stores/terminal/location/available`,
      method: "GET",
      authRequest: true,
      body: payload,
    };

    let httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },

  FETCH_TERMINAL_BY_BUSINESS: async function(context) {
    const httpReq = {
      // url: `v1/stores/getallterminals`,
      url: `/v1/stores/businesses/${GET_USER_BUSINESS_ID()}/terminals`,
      method: "GET",
      authRequest: true,
    };

    let httpRes = await HTTP_REQUEST(httpReq);
    context.commit("POPULATE_ALL_TERMINALS", httpRes);
    return httpRes;
  },

  FETCH_TERMINAL_BY_SERIALNUMBER: async function(context, payload) {
    const httpReq = {
      url: `https://tractionapp-stage.herokuapp.com/v1/stores/terminalbyserialno/business/${payload.businessID}/terminal/${payload.terminalProviderId}/serial/${payload.serialNo}`,
      method: "GET",
      authRequest: true,
    };

    let httpRes = await HTTP_REQUEST(httpReq);
    context.commit("SINGLE_TERMINAL", httpRes);
  },

  EDIT_TERMINAL: async function(context, payload) {
    const httpReq = {
      url: `https://tractionapp-stage.herokuapp.com/v1/stores/updateterminalbyserialnumber/${payload.id}`,
      method: "PATCH",
      authRequest: true,
      body: payload.data,
    };
    let httpRes = await HTTP_REQUEST(httpReq);
    return httpRes;
  },
};

const mutations = {
  POPULATE_ALL_TERMINAL_NAMES: (state, data) => {
    state.allTerminalNames = data;
  },
  POPULATE_ALL_TERMINAL_PRICINGS: (state, data) => {
    state.terminalPricing = data;
  },
  POPULATE_ADD_TERMINAL: (state, data) => {
    state.newTerminal = data;
  },
  POPULATE_ALL_TERMINAL_SERIAL_NUMBERS: (state, data) => {
    // state.allTerminalSerialNumbers = data;
  },
  POPULATE_ALL_TERMINALS: (state, data) => {
    state.allTerminals = data;
  },
  SINGLE_TERMINAL: (state, payload) => {
    state.singleTerminal = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
